<template>
  <div data-v-6cde322f="" class="pager_block">
    <div class="page-list">
      <ul class="pagination">
        <li class="first"><a @click="movePage(1)"><span>처음</span></a></li>
        <li class="prev"><a @click="movePage(payload.page - 1)"><span>이전</span></a></li>
        <li class="pages"><a v-for="row in pagination.end" @click="movePage(row)" :key="'paginated' + row" class="on"><span>1</span></a></li>

        <li class="next"><a @click="movePage(payload.page + 1)"><span>다음</span></a></li>
        <li class="last"><a @click="movePage(payload.end + 1)"><span>끝</span></a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['pagination', 'payload', 'reqData'],
  components: {},
  name: "Pagination",
  methods: {
    movePage: function (data) {
      this.payload.page = data;
      this.reqData(this.payload);
    },
  },
}
</script>
