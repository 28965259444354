import axios from 'axios';
import authHeader from './auth-header';
const KPLAY_BASE_URL = process.env.VUE_APP_KPLAY_URL;
import store from '../store'

class CasinoAuthService {
  casinoPost(path, data) {
    return axios
      .post(KPLAY_BASE_URL + path, data, { headers: authHeader() })
      .then(
        (response) => {return response},
        (error) => {
          if (error.response.status === 401) {
            // alert("인증이 만료되었습니다. 다시 로그인해주세요");
            store.commit('LOGOUT');
          }
          return Promise.reject(error);
        },
      )
      ;
  }
}
export default new CasinoAuthService();
