<template>
  <footer id="footer" class="touch-none">
    <ul>
      <li class="">
        <router-link :to="{ name: '입금'}" aria-label="입금"><span class="icon-deposit"></span>
          <div>입금</div>
        </router-link>
      </li>
      <li class="">
        <router-link :to="{ name: '출금'}" aria-label="출금"><span class="icon-withdraw"></span>
          <div>출금</div>
        </router-link>
      </li>
      <li class="active">
        <router-link :to="{ name: '메인'}" aria-label="홈"><span class="icon-home"></span>
          <div>홈</div>
        </router-link>
      </li>
      <li class="">
        <router-link :to="{ name: '이벤트-목록'}" aria-label="이벤트"><span class="icon-event"></span>
          <div>이벤트</div>
        </router-link>
      </li>
      <li class="">
        <router-link :to="{ name: '고객센터-목록'}"><span class="icon-customer"></span>
          <div>고객센터</div>
        </router-link>
      </li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: "MFooter",
}
</script>

<style scoped>

</style>
