<template>
  <header id="header" v-if="!user">
    <div class="mask" style="display: none;"></div>
    <div class="wrapper wallet wrapper-notlogin">
      <a class="icon-left-menu" @click="$store.dispatch('TOGGLE_MMENU')">
        <span class="icon-nav2"></span>
      </a>
      <!--      <a class="guestmail">비회원 문의</a>-->
      <a class="logo"><img src="@/assets/img/main/main_toplogo.png" alt="logo"></a>
      <div class="btn-box">
        <button class="signup-btn" @click="$store.dispatch('SHOW_MODAL', 'signup')">회원가입</button>
        <button class="login-btn" @click="$store.dispatch('SHOW_MODAL', 'login')">로그인</button>
      </div>
    </div>
  </header>
  <header id="header" v-else>
    <div class="mask" style="display: none;"></div>
    <div class="wrapper wallet wrapper-login">
      <a class="icon-left-menu" @click="$store.dispatch('TOGGLE_MMENU')">
        <span class="icon-nav2"></span>
      </a><!---->
      <a class="logo"><img src="@/assets/img/main/main_toplogo.png" alt="logo"></a><!---->
      <div class="header-wrap"><a class="refresh right-2vw!"><span
          class="icon-pw" style="display: none;"></span><img
          src="data:image/svg+xml,%3c?xml%20version='1.0'%20encoding='utf-8'?%3e%3c!--Generator:%20Adobe%20Illustrator%2025.2.3,%20SVG%20Export%20Plug-In%20.%20SVG%20Version:%206.00%20Build%200)--%3e%3csvg%20version='1.1'%20id='_x30_36_x5F_icconLOAD'%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%20x='0px'%20y='0px'%20viewBox='0%200%20100%20100'%20style='enable-background:new%200%200%20100%20100'%20xml:space='preserve'%3e%3cstyle%20type='text/css'%3e.st0{fill:%236A7391;}%3c/style%3e%3cpath%20class='st0'%20d='M85.4,85.3c9.4-9.4,14.6-22,14.6-35.3c0-27.5-22.4-50-50-50v9.7c22.2,0,40.3,18.1,40.3,40.3%20c0,10.8-4.2,20.9-11.8,28.5L77,80L63.8,66.8V100H97L83.9,86.8L85.4,85.3z'/%3e%3cpath%20class='st0'%20d='M9.7,50c0-10.8,4.2-20.9,11.8-28.5L23,20l13.2,13.2V0H2.9l13.2,13.2l-1.5,1.5C5.2,24.1,0,36.7,0,50%20c0,27.5,22.4,50,50,50v-9.7C27.8,90.3,9.7,72.2,9.7,50'/%3e%3c/svg%3e"
          class="" alt=""></a></div>
      <div class="header-wrap">
        <div class="header-option before:hidden pr-6vw" data-pc-name="dropdown" data-pc-section="root" id="pv_id_2">
          <span class="" tabindex="-1" role="combobox" aria-label="KRW" aria-haspopup="listbox" aria-expanded="false"
                aria-controls="pv_id_2_list" aria-disabled="true" data-pc-section="input"><li><div class="option KRW"><svg
              aria-hidden="true" style="width: 20px; height: 20px;"><use xlink:href="#icon-icon-krw" fill="#333"></use></svg></div><div
              class="coin">₩ {{ user.members_cash | makeComma }}</div></li></span><!---->
          <div class="hidden" data-pc-section="trigger">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                 aria-hidden="true" class="" data-pc-section="dropdownicon">
              <path
                  d="M7.01744 10.398C6.91269 10.3985 6.8089 10.378 6.71215 10.3379C6.61541 10.2977 6.52766 10.2386 6.45405 10.1641L1.13907 4.84913C1.03306 4.69404 0.985221 4.5065 1.00399 4.31958C1.02276 4.13266 1.10693 3.95838 1.24166 3.82747C1.37639 3.69655 1.55301 3.61742 1.74039 3.60402C1.92777 3.59062 2.11386 3.64382 2.26584 3.75424L7.01744 8.47394L11.769 3.75424C11.9189 3.65709 12.097 3.61306 12.2748 3.62921C12.4527 3.64535 12.6199 3.72073 12.7498 3.84328C12.8797 3.96582 12.9647 4.12842 12.9912 4.30502C13.0177 4.48162 12.9841 4.662 12.8958 4.81724L7.58083 10.1322C7.50996 10.2125 7.42344 10.2775 7.32656 10.3232C7.22968 10.3689 7.12449 10.3944 7.01744 10.398Z"
                  fill="currentColor"></path>
            </svg>
          </div><!----></div>
      </div>
    </div>
  </header>
</template>

<script>

import {mapState} from "vuex";
import MSG from "@/contants/msg";

export default {
  name: "MHeader",
  // props: ['user'],
  data() {
    return {
      isView: false,
      isView2: false,
      expendedSubMenu: [],
    }
  },
  computed: {
    menuVisible() {
      return this.$store.getters['MENU_VISIBLE'];
    },
    inplayVisible() {
      return this.menuVisible?.find(e => e.name === '인플레이').visible
    },
    europeanVisible() {
      return this.menuVisible?.find(e => e.name === '스포츠-유럽형').visible
    },
    koreanVisible() {
      return this.menuVisible?.find(e => e.name === '스포츠-한국형').visible
    },
    korean2Visible() {
      return this.menuVisible?.find(e => e.name === '스포츠-한국형2').visible
    },
    liveVisible() {
      return this.menuVisible?.find(e => e.name === '라이브').visible
    },
    lotusOEVisible() {
      return this.menuVisible?.find(e => e.name === '로투스-홀짝').visible
    },
    lotusBaccarat1Visible() {
      return this.menuVisible?.find(e => e.name === '로투스-바카라1').visible
    },
    tokenRouletteVisible() {
      return this.menuVisible?.find(e => e.name === '토큰-룰렛').visible
    },
    tokenBaccaratVisible() {
      return this.menuVisible?.find(e => e.name === '토큰-바카라').visible
    },
    tokenHighlowVisible() {
      return this.menuVisible?.find(e => e.name === '토큰-하이로우').visible
    },
    tokenHighlow5sVisible() {
      return this.menuVisible?.find(e => e.name === '토큰-하이로우5초').visible
    },
    powerballVisible() {
      return this.menuVisible?.find(e => e.name === '파워볼').visible
    },
    powerladderVisible() {
      return this.menuVisible?.find(e => e.name === '파워사다리').visible
    },
    ...mapState({
      user: state => state.user.user,
    }),
  },
  methods: {
    logOut: function () {
      localStorage.removeItem('authToken');
      this.$store.dispatch("LOGOUT", null)
      this.$router.push({path: '/main'})
    },
    toggleMenu(menu) {
      console.log(menu);
      const index = this.expendedSubMenu.indexOf(menu);
      if (index < 0) return this.expendedSubMenu.push(menu);
      this.expendedSubMenu.splice(index, 1)
    },
    openCasino: function (game) {
      if (!this.user) return alert(MSG.isNotLogin);
      const config = this.user.members_detail_setting;
      this.$store.dispatch('CHECK_CASINO_WALLET');
      if (game === 'evolution' && !this.menuVisible.find(e => e.name === '에볼루션').visible) {
        return this.$store.dispatch('SHOW_ERR_MSG3', '에볼루션 점검중입니다.');
      }
      if (game === 'asia' && !this.menuVisible.find(e => e.name === '아시아').visible) {
        return this.$store.dispatch('SHOW_ERR_MSG3', '아시아는 점검중입니다.');
      }
      if (game === 'evolution' && !config.베팅권한.에볼루션.active) {
        this.$store.dispatch('SET_ERR_MSG', '에볼루션은 이용하실 수 없습니다.');
        this.$store.dispatch('SHOW_MODAL', 'error2');
        return;
      }
      if (game === 'asia' && !config.베팅권한.아시아게이밍.active) {
        this.$store.dispatch('SET_ERR_MSG', '아시아게이밍은 이용하실 수 없습니다.');
        this.$store.dispatch('SHOW_MODAL', 'error2');
        return;
      }
      const data = {
        payload: {
          type: game === 'evolution' ? 45 : 44,
        }
      }
      this.$store.dispatch('GET_LOBBY', data)
          .then(data => {
            const result = data.data;
            if (result.payload) {
              window.open(result.payload.data.lobbyUrl, 'Casino', '_blank')
            }
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
  }
}
</script>

<style scoped>

.header-wrap {
  display: flex;
  max-width: 56vw;
  width: auto;
  margin: 0;
  justify-content: flex-end;
  right: 3.4vw;
  position: absolute;
  user-select: none;
  align-items: center;
}

.wrapper-login.wallet .header-wrap {
  display: flex;
}
</style>