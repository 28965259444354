import axios from 'axios';
const BASE_URL = process.env.VUE_APP_API_URL;
import store from '../store'

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common['Authorization'] = null;
const sourceRequest = {};

axios.interceptors.request.use(request => {
    // console.log(request, 'request');
    if (request.method === 'post' && request.url?.includes('process')) {
        const key = `${request.url}$${JSON.stringify(request.data)}`;
    
        if (sourceRequest[key]) {
            console.log(new Date(), sourceRequest[key], new Date() - sourceRequest[key]);
            if (new Date() - sourceRequest[key] <= 5000){
                console.log('Automatic cancellation');
                throw new Error('Automatic cancellation'); // If the request exists cancel
            }
        } else {
            sourceRequest[key] = new Date(); // Store request key
        }
    }
    if (!request.headers.Authorization) {
        const token = JSON.parse(localStorage.getItem("token"));
        if (token) {
            request.headers.Authorization = `Bearer ${token.jwt_token}`;
        }
    }
    // Edit request config
    return request;
}, error => {
    // console.log(error, 'requestError');
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    // console.log(response, 'response');
    // Edit response config
    return response;
}, error => {
    // console.log(error.response, 'responseError');
    if (error.response.status === 401) store.dispatch('REFRESH');
    if (error.response.status === 419) store.dispatch('LOGOUT');
    return Promise.reject(error);
});

export default axios;
