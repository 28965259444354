<template>
  <div class="allContent noticeContent" v-if="!isMobile">
      <div class="rightContent homePage point">
          <div class="contents">
              <div class="main_content_continer">
                  <div class="main-content">
                      <div class="aniFrame">
                          <div class="titlePic">
                              <div class="titlePwrapper">
                                  <div class="leftZone">
                                      <span class="Logo"></span>
                                      <span>상세정보</span>
                                  </div>
                                  <div class="line"></div>
                              </div>
                          </div>
                          <div class="main_content">
                              <div class="main_content v_deep_member_point">
                                  <div class="main_content_wrap main_content_wrap_notice">
                                      <div class="fundFrame noticeFrame myFrame point">
                                          <div class="listZone listZoneA listPoint01">
                                              <table>
                                                  <tbody>
                                                  <tr>
                                                      <td class="al subject">보유 포인트</td>
                                                      <td class="al fB">
                                                          <div class="point-wallet">
                                                              <span class="icon-iconExclamation01">
                                                                  <div class="hover-msg">최근 입금과 동일한 통화로만 전환 가능합니다.</div>
                                                              </span>
                                                          </div>
                                                          {{ user.members_point | makeComma}} P <span class="icon-icconArrow02"></span>
                                                          <div class="point-coin">
                                                              <div class="coin">
                                                                  <div class="coin-frame">
                                                                      <svg aria-hidden="true" class="w-25px h-25px mr-3px"
                                                                          style="width: 24px; height: 24px;">
                                                                          <use xlink:href="#icon-icon-krw"
                                                                              fill="#333"></use>
                                                                      </svg>
                                                                      {{ chargeForm.amount | makeComma }}KRW
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </td>
                                                  </tr>
                                                  <tr>
                                                      <td class="al subject">포인트 전환</td>
                                                      <td class="al">
                                                          <div class="inputFrame">
                                                              <div class="input_content"><span data-pc-name="inputnumber"
                                                                                              data-pc-section="root"><input
                                                                      role="spinbutton" class="" aria-valuenow="0"
                                                                      inputmode="numeric" data-pc-name="input"
                                                                      data-pc-extend="inputtext" data-pc-section="root">
                                                                  <!----><!----><!----></span></div>
                                                              <div class="moneyBtns">
                                                                  <div class="btnM all" @click="changeAmont(user.members_point)">전체 포인트</div>
                                                                  <div class="btnM" @click="changeAmont(10000)">10,000 p</div>
                                                                  <div class="btnM" @click="changeAmont(30000)">30,000 p</div>
                                                                  <div class="btnM" @click="changeAmont(50000)">50,000 p</div>
                                                                  <div class="btnM" @click="changeAmont(100000)">100,000 p</div>
                                                                  <div class="btnM" @click="changeAmont(300000)">300,000 p</div>
                                                                  <div class="btnM" @click="changeAmont(500000)">500,000 p</div>
                                                                  <div class="btnM" @click="changeAmont(1000000)">1,000,000 p</div>
                                                              </div>
                                                          </div>
                                                          <div class="moneyBtns money-btns-point">
                                                              <div class="btnM exchange" @click="processChange">보유머니로 전환</div>
                                                              <div class="btnM reset" @click="resetAmount">초기화</div>
                                                          </div>
                                                      </td>
                                                  </tr>
                                                  </tbody>
                                              </table>
                                          </div>
                                          <div >
                                              <div class="listZone listZone01 listPoint02">
                                                  <div class="title">포인트 내역</div>
                                                  <table>
                                                      <tbody >
                                                      <tr >
                                                          <th class="ac" width="25%">시간</th>
                                                          <th class="ac" width="25%">내용</th>
                                                          <th class="ac" width="25%">유형</th>
                                                          <th class="ac name" width="25%">포인트</th>
                                                          <!-- <th class="ac" width="16%">더 보기</th>
                                                          <th class="ac date" width="16%">메모</th> -->
                                                      </tr>
                                                      <tr v-for="(row, index) in lists" :key="'point_list' + index">
                                                        <td class="ac">{{ row.cash_regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>
                                                        <td class="ac">{{ row.cash_reason }}</td>
                                                        <td class="ac">{{ row.cash_type }}</td>
                                                        <td class="ac">{{ row.cash_amount }}</td>
                                                      </tr>
                                                      </tbody>
                                                  </table>
                                                  <div style="display: block;" class="">
                                                      <div class="noDataFrame">
                                                          <div class="pic">
                                                            <img src="@/assets/images/noData-img.png" alt="no-data">
                                                          </div>
                                                          <h2>데이터 없음</h2>
                                                          <h3>Sorry, Check no data</h3>
                                                        </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <section v-else id="content" class="">
    <div class="wrapPage">
      <div class="title-border wrapper">
        <div class="title-p">
          <div>상세정보</div>
          <span>Point Details</span></div>
        <div class="full-table">
          <table class="point">
            <tbody>
            <tr>
              <th class="w35">보유 포인트</th>
              <td class="w65 currency"><span class="icon-icon-alarm2 notice-button"></span><b>{{ user.members_point | makeComma }} P</b><span
                  class="icon-arrow-goback icon-arrow-gonext"></span><span><svg aria-hidden="true" class="absolute"><use
                  xlink:href="#icon-icon-krw" fill="#333"></use></svg><b class="ml-5vw">KRW</b></span></td>
            </tr>
            <tr>
              <th class="w35">포인트 전환</th>
              <td class="w65">
                <span data-pc-name="inputnumber" data-pc-section="root">
                <input role="spinbutton" class=""
                       v-model="chargeForm.amount"
                       type="text">
                </span>
                <div class="amount-btnbox">
                  <button class="amount-button" @click="allAmount">전체 포인트</button>
                  <button class="amount-button" @click="addAmount(10000)">10,000 p</button>
                  <button class="amount-button" @click="addAmount(100000)">100,000 p</button>
                  <button class="amount-button" @click="addAmount(1000000)">1,000,000 p</button>
                </div>
                <button class="reset-button" @click="resetAmount">초기화</button>
                <button class="confirm-button" @click="processChange">보유머니로 전환</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="title-p">
          <div>포인트 내역</div>
        </div>
        <div class="content-table exchange-table">
          <table>
            <thead>
            <tr>
              <th class="w25">시간</th>
              <th class="w25">유형</th>
              <th class="w25">포인트</th>
              <th class="w25">메모</th>
            </tr>
            </thead>
            <tbody></tbody>
            <tbody>
            <tr v-if="lists.length === 0">
              <td colspan="4">
                <p class="noData" style="">
                  <img src="@/assets/images/noData-img.png" alt="no-data">
                  <b>데이터 없음</b>
                  <span>Sorry, Check no data</span>
                </p>
              </td>
            </tr>
            <template v-else>
              <tr v-for="(row, index) in lists" :key="'point_list' + index">
                <td>{{ row.cash_regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>
                <td>{{ row.cash_type }}</td>
                <td>{{ row.cash_amount }}</td>
                <td>{{ row.cash_reason }}</td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
        <div class="absolute! top-0"></div>
        <MPagination
            v-if="pagination"
            :payload="payload"
            :pagination="pagination"
            :req-data="reqData">
        </MPagination>
      </div>
    </div>
  </section>
</template>

<script>
import MSG from '@/contants/msg'
import {mapState} from "vuex";
import MPagination from '@/components/MPagination'

export default {
  name: "PointChange",
  components: {
    MPagination,
  },
  data: function(){
    return {
      amount: 0,
      account: '',
      showReq: false,
      showList: false,
      showDescription: false,
      isLoading: false,
      payload: {
        page: 1,
        size: 20,
        detailType: ['포인트', '수동-포인트']
      },
      chargeForm: {
        amount: 0,
      },
      pagination: {}
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      lists: state => state.user.balanceHistory
    }),
  },
  watch: {
    amount: function(val){
      if (Number(this.user.members_point) <= Number(val)) {
        this.amount = this.user.members_point
      }
    },
  },
  mounted: function() {
    window.scrollTo(0,0);
    this.reqData(this.payload)
  },
  methods: {
    reqData: function(payload){
      this.$store.dispatch('BALANCE_HISTORY', { payload })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
    // 포인트 전환
    processChange: function(){
      this.isLoading = true;
      if (confirm('포인트를 전환 하시겠습니까?')){
        if (this.user.members_point < 5000) {
          this.isLoading = false;
          return alert(MSG.minPointAmount);
        }
        if (this.user.members_point < this.chargeForm.amount) {
          this.isLoading = false;
          return alert(MSG.hasPointErrMsg);
        }
        this.chargeForm.amount = this.user.members_point;
        this.$store.dispatch('PROCESS_POINT_CHANGE', { payload: this.chargeForm })
            .then((response) => {
              if (response.data.success) {
                this.$store.dispatch('ME')
                this.isLoading = false;
              }
            })
      }
    },
    processChangeAmount: function(){
      this.isLoading = true;
      if (confirm('포인트를 전환 하시겠습니까?')){
        if (this.chargeForm.amount < 5000) {
          this.isLoading = false;
          return alert(MSG.minPointAmount);
        }
        if (this.user.members_point < this.chargeForm.amount) {
          this.isLoading = false;
          return alert(MSG.hasPointErrMsg);
        }
        // this.chargeForm.amount = this.user.members_point;
        this.$store.dispatch('PROCESS_POINT_CHANGE_AMOUNT', { payload: this.chargeForm })
            .then((response) => {
              if (response.data.success) {
                this.$store.dispatch('ME')
                this.isLoading = false;
              }
            })
      }
    },
    movePage: function(page){
      this.$router.push({ path: `/${page}`})
    },
    addAmount(amount){
      console.log(amount)
      this.chargeForm.amount += amount;
    },
    resetAmount(){
      this.chargeForm.amount = 0;
    },
    allAmount(){
      this.chargeForm.amount = this.user.members_point;
    }
    // 기록 삭제
  }
}
</script>

<style scoped>
@media (min-width: 600px) {
  .ac .pointFlow[data-v-ece01014] {
    min-height: 15px;
    text-decoration: underline!important;
    cursor: pointer!important
}

 .v_deep_member_point .myFrame .listPoint02 table {
    border-top: none!important;
    border-bottom: none!important
}

 .v_deep_member_point .myFrame .listPoint01 td.subject {
    color: var(--txtColor_017)!important;
    font-weight: 100
}

 .v_deep_member_point .main_content_wrap_notice .listZone table tr td .btnP {
    color: var(--bdColor058);
    padding: 8px;
    border-radius: 5px;
    float: left;
    margin-left: 10px;
    cursor: pointer
}

 .v_deep_member_point .main_content_wrap_notice .listZone table tr td.fB {
    display: flex;
    align-items: center
}

 .v_deep_member_point .myFrame .listPoint02 .title {
    color: var(--txtColor_05);
    text-align: center;
    margin-bottom: 25px;
    margin-top: 25px
}

 .v_deep_member_point .main_content_wrap_notice .myFrame .listPoint02 table tr:first-child {
    border-top: 1px solid var(--bdColor047)!important
}

 .v_deep_member_point .main_content_wrap_notice .myFrame .listPoint02 table tr:last-child {
    border-bottom: 1px solid var(--bdColor047)!important
}

 .v_deep_member_point .main_content_wrap_notice .myFrame .listPoint02 table tr, .v_deep_member_point .main_content_wrap_notice .myFrame .listPoint01 table tr {
    border-left: 1px solid var(--bdColor047)!important;
    border-right: 1px solid var(--bdColor047)!important
}

 .v_deep_member_point .main_content_wrap_notice .listZone {
    position: relative
}

 .v_deep_member_point .noticeFrame .listZone .input_content {
    width: 15%;
    height: 40px;
    padding-left: 11px;
    background-repeat: no-repeat;
    background-position: 10px;
    box-sizing: border-box;
    border-radius: 3px;
    position: relative;
    color: var(--txtColor_029);
    background-color: var(--main);
    border: 1px solid var(--bdColor_008)
}

 .v_deep_member_point .noticeFrame .listZone .input_content:active, .v_deep_member_point .noticeFrame .listZone .input_content:focus, .v_deep_member_point .noticeFrame .listZone .input_content:focus-within, .v_deep_member_point .noticeFrame .listZone .input_content:target {
    border: 1px solid var(--bdColor064)!important
}

 .v_deep_member_point .noticeFrame .listZone .input_content input {
    border-bottom: none!important
}

 .v_deep_member_point .noticeFrame .listZone .input_content input:focus {
    border-bottom: none!important;
    box-shadow: none!important
}

 .v_deep_member_point .noticeFrame .listZone .input_content input::placeholder {
    color: var(--txtColor66);
    font-size: 14px
}

 .v_deep_member_point .noticeFrame .listZone .inputFrame {
    display: flex;
    margin-bottom: 5px
}

 .v_deep_member_point .noticeFrame .listZone .hint {
    margin-top: 12px
}

 .v_deep_member_point .noticeFrame .listZone .hint01 {
    margin-top: -10px;
    margin-left: -11px
}

 .v_deep_member_point .noticeFrame .listZone .hint02 {
    margin-top: 12px;
    margin-left: 1px
}

 .v_deep_member_point .noticeFrame .listZone .hint span {
    display: block;
    float: left;
    margin-right: 6px;
    color: var(--btnColor024)
}

 .v_deep_member_point .noticeFrame .listZone .hint .txt {
    color: var(--btnColor024)
}

 .v_deep_member_point .fundFrame .moneyBtns .btnM {
    color: var(--txtColor_09)!important;
    box-sizing: border-box;
    border: 2px solid var(--btnColor_001)!important
}

 .v_deep_member_point .fundFrame .moneyBtns .btnM:hover, .v_deep_member_point .fundFrame .moneyBtns .btnM:focus {
    background: var(--btnColor_001)!important;
    color: var(--txtColor_08)!important
}

 .v_deep_member_point .fundFrame .moneyBtns .all {
    background: var(--btnColor_001)!important;
    color: var(--txtColor_01)!important;
    border-radius: 5px
}

 .v_deep_member_point .fundFrame .moneyBtns .all:hover {
    background-color: var(--btnColor_001_h)
}

 .v_deep_member_point .fundFrame .money-btns-point {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0
}

 .v_deep_member_point .fundFrame .moneyBtns .exchange {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--txtColor_01)!important;
    padding: 6px;
    font-size: 14px;
    cursor: pointer;
    width: 15%;
    background-color: var(--btnColor_004);
    border: none!important;
    border-radius: 5px;
    transition: .3s ease-out
}

 .v_deep_member_point .fundFrame .moneyBtns .exchange:hover {
    background-color: var(--btnColor_004_h)!important
}

 .v_deep_member_point .fundFrame .money-btns-point .reset {
    padding: 0 36px;
    margin-left: 3px;
    color: var(--txtColor01)!important;
    box-sizing: border-box;
    background: var(--btnColor_008)!important;
    color: var(--txtColor_01)!important;
    border-radius: 5px;
    border: none!important
}

 .v_deep_member_point .fundFrame .money-btns-point .reset:hover {
    background: var(--btnColor_008_h)!important
}

 .v_deep_member_point .main_content_wrap_notice .listZone table tr td .more {
    border: 1px solid var(--bdColor043);
    background: none;
    margin-left: 8px;
    border-radius: 5px;
    padding: 3px 6px;
    position: relative
}

 .v_deep_member_point .main_content_wrap_notice .listZone table tr td .more span {
    color: var(--txtColor_017)
}

 .v_deep_member_point .main_content_wrap_notice .listZone table tr td .more .hover-msg {
    position: absolute;
    padding: 10px 18px;
    border: 1px solid var(--bdColor047);
    background-color: var(--main)!important;
    border-radius: 5px;
    bottom: 39px;
    right: -5px;
    display: none
}

 .v_deep_member_point .main_content_wrap_notice .listZone table tr td .more:hover .hover-msg {
    display: block
}

 .v_deep_member_point .main_content_wrap_notice .listZone table tr td .more .hover-msg:after {
    content: "";
    background-image: url(/KD001-05/assets/images/shared/common/hover-msg01.png);
    width: 13px;
    height: 12px;
    position: absolute;
    bottom: -12px;
    right: 11px
}

 .v_deep_member_point .main_content_wrap_notice .listZone table tr td .more .hover-msg p {
    text-align: justify;
    white-space: nowrap;
    line-height: 1.6;
    color: var(--txtColor_017)
}

 .v_deep_member_point .main_content_wrap_notice .listZone table tr td span.negative-number {
    color: var(--txtColor49);
    font-weight: 100
}

 .v_deep_member_point .main_content_wrap_notice .listZone table tr td a {
    color: var(--txtColor_022)!important;
    cursor: pointer;
    text-decoration: underline
}

 .v_deep_member_point .main_content_wrap_notice .listZone table tr td .point-wallet {
    display: flex;
    align-items: center
}

 .v_deep_member_point .main_content_wrap_notice .listZone table tr td span.icon-iconExclamation01 {
    color: var(--txtColor_022)!important;
    font-size: 20px;
    margin-right: 8px;
    cursor: pointer;
    position: relative
}

 .v_deep_member_point .main_content_wrap_notice .listZone table tr td span.icon-icconArrow02 {
    transform: rotate(180deg);
    margin-left: 14px;
    font-size: 20px;
    color: #ccd2e3
}

 .v_deep_member_point .main_content_wrap_notice .listZone table tr td .point-wallet .hover-msg {
    background: #f5f5f5;
    padding: 12px 10px;
    position: absolute;
    top: -46px;
    display: none;
    box-shadow: 4px 4px 8px #00000080;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    border-radius: 5px;
    white-space: nowrap;
    color: var(--txtColor_017)
}

 .v_deep_member_point .main_content_wrap_notice .listZone table tr td .point-wallet span.icon-iconExclamation01:hover .hover-msg {
    display: flex;
    transition: all .2s ease-out
}

 .v_deep_member_point .main_content_wrap_notice .listZone table tr td .point-coin {
    margin-left: 14px
}

 .v_deep_member_point .main_content_wrap_notice .listZone table tr td .point-coin .coin .coin-frame {
    display: flex!important;
    align-items: center
}

 .v_deep_member_point .main_content_wrap_notice .listZone table tr td .point-coin .coin img {
    width: 25px;
    margin-right: 3px
}

 .v_deep_member_point .main_content_wrap_notice .listPoint02 table tr td .point-coin {
    justify-content: center;
    display: flex;
    margin-left: 0
}

 .v_deep_member_point .main_content_wrap_notice .listZone table tr td {
    vertical-align: middle
}

 .v_deep_member_point .blueB {
    transition: .3s ease-out;
    background-color: var(--btnColor025)
}

}

@media (max-width: 600px) {
  .popup-point-flow .content .bgbtn-box button {
  width: 100%
}

.popup-point-flow .content .tip {
  text-align: right
}

.memo-button .pointFlow {
  word-break: break-all
}

.full-table table.point {
  width: 100vw
}

.full-table table.point tbody tr th, .full-table table.point tbody tr td {
  position: relative;
  text-align: left;
  padding: 1.4vw;
  font-size: .81rem;
  border-bottom: 1px solid var(--borderColor09);
  color: var(--txtColor07);
  vertical-align: middle
}

.full-table table.point tbody tr th {
  font-weight: 600;
  background: var(--bgColor33);
  padding: 2.4vw;
  text-align: center
}

.full-table table.point tbody tr td input, .full-table table.point tbody tr td textarea {
  border: 1px solid var(--borderColor05);
  padding: 2vw;
  width: 100%;
  line-height: 1.6;
  font-size: .86rem;
  font-family: Microsoft JhengHei, sans-serif
}

.full-table table.point tbody tr td p {
  margin-bottom: 1vw
}

.full-table table.point tbody tr td.currency span {
  margin: 0 1vw
}

.full-table table.point tbody tr td.currency span.icon-icon-alarm2 {
  font-size: 1.1rem;
  vertical-align: text-top;
  color: var(--txtColor32)
}

.full-table table.point tbody tr td.currency span.icon-arrow-goback {
  position: relative;
  padding: 0 1.5vw
}

.full-table table.point tbody tr td.currency span.icon-arrow-goback:before {
  transform: rotate(180deg);
  position: absolute;
  left: 0vw;
  top: 0
}

.full-table table.point tbody tr td.currency span.coin-frame {
  background-size: 4vw;
  background-position: left center;
  background-repeat: no-repeat;
  padding: 1vw 0 1vw 5vw
}

/*
.full-table table.point tbody tr td.currency span.coin-frame.USDT {
  background-image: url(/KD001-05/Shared/images/wallet/USDT.png)
}

.full-table table.point tbody tr td.currency span.coin-frame.ETH {
  background-image: url(/KD001-05/Shared/images/wallet/ETH.png)
}

.full-table table.point tbody tr td.currency span.coin-frame.BTC {
  background-image: url(/KD001-05/Shared/images/wallet/BTC.png)
}

.full-table table.point tbody tr td.currency span.coin-frame.XRP {
  background-image: url(/KD001-05/Shared/images/wallet/XRP.png)
}

.full-table table.point tbody tr td.currency span.coin-frame.KRW {
  background-image: url(/KD001-05/Shared/images/wallet/KRW.png)
}
*/
button {
  width: 100%;
  padding: 2vw;
  margin-bottom: 1vw
}

button.reset-button {
  background: var(--bgColor24);
  color: var(--txtColor01)
}

button.confirm-button {
  background: var(--btnColor10);
  color: var(--txtColor01)
}

.amount-btnbox button.amount-button {
  width: 49%;
  margin-bottom: 1vw
}

.exchange-table table thead tr th {
  line-height: 1.2
}

.exchange-table table tbody tr td {
  padding: 1.4vw;
  width: 20vw;
  min-width: 20vw
}

.exchange-table table tbody tr td:first-child {
  width: 25vw;
  min-width: 25vw
}

.exchange-table table tbody tr td:nth-child(4) {
  width: 15vw;
  min-width: 15vw
}

.exchange-table table tbody tr td span.txt {
  width: 58%;
  max-width: 58%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  text-align: left
}

.content-table table tbody tr td span.icon-detail {
  margin-left: 0vw
}

.content-table table tbody tr td span.coin-frame {
  background-size: 4vw;
  background-position: left center;
  background-repeat: no-repeat;
  padding: 1vw 0 1vw 5vw
}

/*
.content-table table tbody tr td span.coin-frame.USDT {
  background-image: url(/KD001-05/Shared/images/wallet/USDT.png)
}

.content-table table tbody tr td span.coin-frame.ETH {
  background-image: url(/KD001-05/Shared/images/wallet/ETH.png)
}

.content-table table tbody tr td span.coin-frame.BTC {
  background-image: url(/KD001-05/Shared/images/wallet/BTC.png)
}

.content-table table tbody tr td span.coin-frame.XRP {
  background-image: url(/KD001-05/Shared/images/wallet/XRP.png)
}

.content-table table tbody tr td span.coin-frame.KRW {
  background-image: url(/KD001-05/Shared/images/wallet/KRW.png)
}
*/
.popup-point-flow .pop-up-content {
  margin: 2vw auto
}

.hint-small-content .btn-box button.close-button {
  background: var(--bgColor02)
}

.popup-point-flow .content table thead tr th {
  padding: 5px
}

.popup-point-flow .content table tbody tr td.point {
  font-size: .72rem
}

.popup-point-flow .content .bgbtn-box {
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.popup-point-flow .content .bgbtn-box button {
  font-size: .875rem;
  margin-top: 4vw
}

.popup-point-flow .content p.point {
  font-size: .75rem;
  text-align: center;
  margin-bottom: 3vw;
  display: none
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .full-table table.point tbody tr th, .full-table table.point tbody tr td, .full-table table.point tbody tr td input, .full-table table.point tbody tr td textarea {
    font-size: 1.6rem
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .full-table table.point tbody tr th, .full-table table.point tbody tr td, .full-table table.point tbody tr td input, .full-table table.point tbody tr td textarea {
    font-size: 2.4rem
  }
}
}


</style>
