const getDefaultState = () => {
    return {
        signup: false,
        mmenu: false,
        login: false,
        cart: false,
        notes: false,
        bettingSuccess: false,
        bettingStatus: false,
        bettingUpdate: false,
        bettingUpdateProcess: false,
        bettingProcess: false,
        bettingProcessSingle: false,
        bettingProcessKoreanMulti: false,
        bettingProcessKoreanSingle: false,
        bettingProcessLsportEuropeanMulti: false,
        bettingProcessLsportEuropeanSingle: false,
        bettingProcessSpecialMulti: false,
        bettingProcessSpecialSingle: false,
        bettingProcessEuropeanMulti: false,
        bettingProcessEuropeanSingle: false,
        bettingProcessInplayMulti: false,
        bettingProcessInplaySingle: false,
        bettingProcessLiveMulti: false,
        bettingProcessLiveSingle: false,
        bettingProcessing: false,
        bettingMiniProcess: false,
        bettingCancelProcess: false,
        bettingCancelSuccess: false,
        bettingLotusProcess: false,
        error: false,
        error2: false,
        error3: false,
        roulette: false,
        fixing: false,
        errorMsg: null,
        deduction: false,
        deposit: false,
        withdrawal: false,
    };
}
const state = getDefaultState();

const getters = {
    BACKGROUND: function (state) {
        let result = false;
        for (const x in state) {
            if (x !== 'errorMsg') {
                if (state[x]) result = true;
            }
        }
        return result;
    },
};

const actions = {
    SHOW_MODAL({commit}, data) {
        console.log(data)
        commit('OPEN_POPUP', data)
    },
    TOGGLE_MMENU({commit}) {
        commit('TOGGLE_MENU')
    },
    INVISIBLE_MODAL({commit}, data) {
        commit('CLOSE_POPUP', data)
    },
    INVISIBLE_ALL_MODALS({commit}) {
        commit('CLOSE_ALL_POPUP')
    },
    SHOW_ERR_MSG({commit}, msg) {
        commit('CLOSE_ALL_POPUP')
        commit('SET_ERROR_MSG', msg)
        commit('OPEN_POPUP', 'error')
    },
    SHOW_ERR_MSG2({commit}, msg) {
        commit('CLOSE_ALL_POPUP')
        commit('SET_ERROR_MSG', msg)
        commit('OPEN_POPUP', 'error3')
    },
    SHOW_ERR_MSG3({commit}, msg) {
        commit('CLOSE_ALL_POPUP')
        commit('SET_ERROR_MSG', msg)
        commit('OPEN_POPUP', 'error2')
    },
    SET_ERR_MSG({commit}, data) {
        commit('SET_ERROR_MSG', data)
    },
    setEventPopup({commit}, data) {
        commit('SET_EVENT_POPUP', data)
    }
}


const mutations = {
    TOGGLE_MENU(state) {
        state.mmenu = !state.mmenu;
    },
    OPEN_POPUP(state, data) {
        state[data] = true;
    },
    CLOSE_POPUP(state, data) {
        state[data] = false;
    },
    CLOSE_ALL_POPUP(state) {
        for (const x in state) {
            state[x] = false;
        }
    },
    SET_ERROR_MSG(state, data) {
        state.errorMsg = data;
    },
    SET_EVENT_POPUP(state, data) {
        state.event_popup = data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}
