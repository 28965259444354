<template>
                  <div  class="footer footer_notice">
                    <div  class="footerWrapper footerWrapperReverse">
                        <div  class="topZone">
                            <div  class="leftZone">
                                <div class="logoFrame">
                                    <div class="logos"><img src="/assets/logo-C5uUT3__.svg" alt=""></div>
                                    <div class="txt01"> Bet38 is owned and operated by Regnum<br> Incorporate N.V..
                                        registration number :
                                    </div>
                                </div>
                                <div class="txt"> 165025, registered address : Curaçao. Contact us info@bet38.com Bet38
                                    is licensed and regulated by the Government of the Autonomous Island of Anjouan,
                                    Union of Comoros and operates under License No. ALSI-142311018-FI2. Bet38 has passed
                                    all regulatory compliance and is legally authorized to conduct gaming operations for
                                    any and all games of chance and wagering.
                                </div>
                                <div class="chatZone partner"></div>
                            </div>
                            <div  class="footRframe">
                                <div  class="centerZone"><!---->
                                    <div class="content">
                                        <ul>
                                            <li data-v-4e98d88a="" class="iconANI"><a data-v-4e98d88a=""><span
                                                    data-v-4e98d88a=""
                                                    class="icon-iiconService icon-icconService01"></span>
                                                <div data-v-4e98d88a="" class="txt">고객센터</div>
                                            </a></li><!---->
                                            <li data-v-4e98d88a="" class="iconANI"><a data-v-4e98d88a=""><span
                                                    data-v-4e98d88a="" class="icon-icconCooperation"></span>
                                                <div data-v-4e98d88a="" class="txt">파트너 문의</div>
                                            </a></li>
                                            <li data-v-4e98d88a="" class="iconANI iconTG"><a data-v-4e98d88a=""><span
                                                    data-v-4e98d88a="" class="icon-icconTELEGRAM"></span>
                                                <div data-v-4e98d88a="" class="txt">공식채널 텔레그램</div>
                                            </a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div  class="rightZone01">
                                    <div  class="links">
                                        <div class="title">라이센스</div>
                                        <div class="content">
                                            <div class="left01"><a>서비스약관</a><a>AML정책</a><a>책임감있는게임</a></div>
                                        </div>
                                    </div>
                                    <div  class="links">
                                        <div class="title">회사소개</div>
                                        <div class="content">
                                            <div class="left01"><a>회사소개</a></div>
                                        </div>
                                    </div>
                                    <div  class="links">
                                        <div class="title">베팅규정</div>
                                        <div class="content">
                                            <div class="left01"><a>스포츠실시간</a><a>스포츠</a><a>해외 스포츠(SABA)</a><a>카지노</a><a>슬롯</a><a>미니게임</a><a>가상게임</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div  class="links">
                                        <div class="title">게임소개</div>
                                        <div class="content">
                                            <div class="left01"><a>스포츠실시간</a><a>스포츠</a><a>해외 스포츠(SABA)</a><a>카지노</a><a>슬롯</a><a>미니게임</a><a>가상게임</a>
                                            </div>
                                        </div>
                                    </div><!----></div>
                            </div>
                        </div>
                        <div data-v-4f765740=""  class="downZone">
                            <div data-v-4f765740="" class="thirdLogo w-100%">
                                <div data-v-4f765740="" class="footerLogo"></div>
                            </div>
                        </div>
                    </div>
                </div>

</template>

<script>
export default {
  name: "Footer",
  methods:{
    movePage: function(page){
      if (page === '') return;
      if (page==='minigame/powerball' || page==='minigame/powerladder') return alert('테스트중입니다.')
      this.$router.push({path: `/${page}`})
    },
  }
}
</script>

<style scoped>
  
</style>
