<template>
  <div class="serviceZone blockZone">
      <div class="title wow fadeInUp" data-wow-duration="0.5s"
           style="visibility: visible; animation-duration: 0.5s; animation-name: fadeInUp;">
          <h2>QUALITY SERVICE</h2>
          <div class="majorTXT"><img width="184" height="34"
                                     src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALgAAAAiCAMAAADbEOPQAAAAkFBMVEUAAADOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nm1L0BAAAAL3RSTlMAGdeqJePmnCj2FWIPUTcK7h7SI5KfBsldV3pqA9tGLcFLMkE9toyGgXG8ma+ldW810agAAAHVSURBVFjDzdfbVoJQFIVhRDTEQyppWmIesMIT7/92rbU3MItYw7gw1n85rz4YDDY4Cmpt/D8M+mq5ade/OeiTkyqFSxr0yVkFlzTok7MKLmnQJ7eqNlzSoEyeqZwH68Jw+jWokucqJ5N75cH/NiiSQ5W7ImlQJS/ccN0eup7TcHDDJQ2q5Lkbw5iHnicMhbzTgFxyY4ALgyq54IYLgyhfUfP5/JEbcAHVoiZUGHqe5/tv1G63G1FxHO/3+/V6vd1uh9SSWlB96pVLkuRKvXCf3Af3zj1lnWbjSncbLgySPFdbsfWGFmuk7GSlMRqfwV0Ni0EMOT3MZpdLu30+Hw6H43Gz2TxT0+nUdd0oijqdLjWmera02g0XBkH+Ez74H3gquOEaYZDkd7vjXAkOeQkeTIthZuHlIXRsGO5/x9ndKT0pghwqDIK7kWdcloOJQXBTK9Oc/baBKTC1uAnHbxjKp0vidtzIFFN7am3acsOspW1h6xclUaUcKgyCu6kCV5CTKh80uiuOoMCe8CEGlUd+jY8sZe4an7XK3DV+JJS5CzlU0qDMDXmuEgdl7sIFlTQoc+cuqIRBnZtdUEmDRje7oJIGjW525SoM3dLQ/M99pfzo1Ru4Lx1Kx8qxJ20qAAAAAElFTkSuQmCC"
                                     alt="">
              <h3>뛰어난 서비스</h3><img width="184" height="35"
                                   src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALgAAAAjCAMAAAAQTDB1AAAAllBMVEUAAADOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+nOz+lzxRgUAAAAMXRSTlMAEubPJ6+bKDQMB7X31kn078jBuoBeVkI7Hqgj6uLe2qCWkYyGeXNua2VQLRkW+6ukh9bCjQAAAYlJREFUWMPN0sdWw0AQRFGNkgEF54xzzvj/fw6NhErgUWPvVLV8q3v6tPU4pV4IhFOOo54GQrly7ne4xMAn1yq4xMAn1yq4xMAn1yq4xMAnz1SeB5cYuOQ/KsvKXXKgkkOVu8ZioJLDDVcoBSo53IVLClTy3I0FKTMoCVRyw61D6jIClVxwZy4EPrngtnMXAplccHtwFcGU612vYRjW6/VGo/H5adv2ZDKdzmZRFDmO02w2W61Wu90OAs+7jUZf87nvLxbL5Wq1Xnc6nc1mu93t9vvDodvt9nq94zGO436/PxgM3pKdTrWaOx4Ph8Pz+XJRSr0n+0j2nxsXRZDk1cCDv24EuAIjWI/ySuBu+rq+hRXBTz+7ZgQL86u7uCwHE0FyVwKX5RGYCIa7QrgsBxPBcCNUMlmuVQiM7hL5KHXejEDmNuRaVR7I3JD/ZpYHMjfkhSoWApkbcqjEQOaGPFO5LwSaZS6oxMDm1i6oxMDo1i6oxMDo1i6oxMDo1i6oxMDoTlzu00Dh/gbu1L78+weDWAAAAABJRU5ErkJggg=="
                                   alt=""></div>
          <h4>하이퀄리티의 서비스를 제공합니다</h4></div>
      <div class="content">
          <ul>
              <li class="wow fadeInLeft" data-wow-duration="0.5s"
                  style="visibility: visible; animation-duration: 0.5s; animation-name: fadeInLeft;">
                  <div class="outFrame">
                      <div class="inner">
                          <div class="pic aniObject01">
                            <img width="99" height="99" src="@/assets/images/etc/diamondIcon.png">
                          </div>
                          <h2>전문적인</h2>
                          <h3>Professional</h3>
                          <p>매일 1,000여 종류의 흥미로운 실시간 스포츠 및 라이브 카지노, 로또, 슬롯게임 등 완벽하고 다양한
                              서비스를 BET38 에서 만나보세요.
                          </p>
                      </div>
                  </div>
              </li>
              <li class="wow fadeInLeft" data-wow-duration="0.5s"
                  style="visibility: visible; animation-duration: 0.5s; animation-name: fadeInLeft;">
                  <div class="outFrame">
                      <div class="inner">
                          <div class="pic aniObject01">
                            <img width="99" height="99" src="@/assets/images/etc/diamondIcon.png" alt="">
                          </div>
                          <h2>안전한</h2>
                          <h3>SAFE</h3>
                          <p>유니크 개발 기술을 통해 128 Bit 암호화 기술과 보안 시스템으로가장 중요한 고객 자금을 철저히 보안되어
                              저희 사이트에서 제공하는 서비스를 마음 것 즐기 실 수 있습니다.</p>
                      </div>
                  </div>
              </li>
              <li class="wow fadeInRight" data-wow-duration="0.5s"
                  style="visibility: visible; animation-duration: 0.5s; animation-name: fadeInRight;">
                  <div class="outFrame">
                      <div class="inner">
                          <div class="pic aniObject01"><img width="99" height="99"
                                                            src="@/assets/images/etc/diamondIcon.png"
                                                            alt=""></div>
                          <h2>편리한</h2>
                          <h3>Convenient</h3>
                          <p>쇼핑몰을 선도하는 독점 기술로 자체 개발을 통해 WEB, H5 페이지 외에 양대 쇼핑몰플렛폼인 iOS 와
                              Android 원생 시스템에서 도 편의하게 즐길 수 있습니다.</p></div>
                  </div>
              </li>
              <li class="wow fadeInRight" data-wow-duration="0.5s"
                  style="visibility: visible; animation-duration: 0.5s; animation-name: fadeInRight;">
                  <div class="outFrame">
                      <div class="inner">
                          <div class="pic aniObject01">
                            <img width="99" height="99" src="@/assets/images/etc/diamondIcon.png" alt="">
                          </div>
                          <h2>빠른</h2>
                          <h3>FAST</h3>
                          <p>안정적인 동시에 최첨단 금융 시스템을 도입하여 가장 빠른 입출금 서비스를 제공하고 있으며, 또한 최고의 게임
                              체험 환경을 제공하기 위해 독점 네트워크 전송 기술이 최적화되어 있습니다.
                          </p>
                      </div>
                  </div>
              </li>
          </ul>
      </div>
  </div>

</template>

<script>
export default {
  name: 'ServiceZone',
}
</script>

<style>
</style>
