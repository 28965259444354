var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isMobile)?_c('div',{staticClass:"allContent noticeContent"},[_c('div',{staticClass:"rightContent homePage"},[_c('div',{staticClass:"contents"},[_c('div',{staticClass:"main_content_continer"},[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"aniFrame"},[_vm._m(0),_c('div',{staticClass:"main_content"},[_c('div',{staticClass:"main_content"},[_c('div',{staticClass:"main_content_wrap main_content_wrap_notice"},[_c('div',{staticClass:"noticeFrame"},[_c('div',{attrs:{"show-field-date":"false"}},[_c('div',{staticClass:"listZone"},[_c('table',[_c('tbody',[_vm._m(1),_vm._l((_vm.lists),function(row,index){return _c('tr',{key:'notice'+index,on:{"click":function($event){return _vm.movePage(row.board_seq)}}},[_c('td',{staticClass:"ac"},[_c('span',[_vm._v(_vm._s(index + 1))])]),_c('td',{staticClass:"al"},[_c('a',{on:{"click":function($event){return _vm.movePage(row.board_seq)}}},[_vm._v(" "+_vm._s(row.board_title)+" ")])]),_c('td',{staticClass:"ac fc"},[_vm._v("관리자")])])})],2)]),_vm._m(2)])])])])])])])])])])])]):_c('section',{attrs:{"id":"content"}},[_c('div',{staticClass:"wrapPage"},[_c('div',{staticClass:"wrapper"},[_vm._m(3),_c('div',{staticClass:"content-table"},[_c('table',[_vm._m(4),_c('tbody',_vm._l((_vm.lists),function(row,index){return _c('tr',{key:'notice'+index,on:{"click":function($event){return _vm.movePage(row.board_seq)}}},[_c('td',{staticClass:"w20"},[_c('span',[_vm._v(_vm._s(index + 1))])]),_c('td',{staticClass:"w60 left"},[_vm._v(_vm._s(row.board_title))]),_vm._m(5,true)])}),0)])]),_c('div',{staticClass:"absolute! top-0"})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titlePic"},[_c('div',{staticClass:"titlePwrapper"},[_c('div',{staticClass:"leftZone"},[_c('span',{staticClass:"icon-iiconLogoB"}),_c('span',[_vm._v("베팅규정")])]),_c('div',{staticClass:"line"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticClass:"ac number",attrs:{"width":"10%"}},[_vm._v(" No.")]),_c('th',{staticClass:"ac",attrs:{"width":"70%"}},[_vm._v("제목")]),_c('th',{staticClass:"ac name",attrs:{"width":"20%"}},[_vm._v("작성자")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"noDataFrame"},[_c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":"/assets/noData-YtuvU2u_.png","alt":"no-data"}})]),_c('h2',[_vm._v("데이터 없음")]),_c('h3',[_vm._v("Sorry, Check no data")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-p"},[_c('div',[_vm._v("베팅규정")]),_c('span',[_vm._v("Notification")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"w20"},[_vm._v(" No.")]),_c('th',{staticClass:"w60"},[_vm._v("제목")]),_c('th',{staticClass:"w20"},[_vm._v("작성자")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"w20"},[_c('span',[_vm._v("관리자")])])
}]

export { render, staticRenderFns }