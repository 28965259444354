<template>
  <div class="menu01">
      <div class="menu01Wrapper">
          <div class="logo">
            <router-link :to="{ name: '메인' }"><img
                  src="@/assets/img/main/main_toplogo.png"
                  alt="">
            </router-link>
          </div>
          <div class="menuZone">
              <ul>
                  <li class="hoverDown active">
                    <router-link :to="{ name:'메인'}" class="menu">홈</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'스포츠-인플레이(벳컨)'}" class="menu" >스포츠
                      <span class="icon-icconMENU"></span>
                    </router-link>
                      <div class="menu-drop">
                          <ul class="drop-ul">
                              <li><router-link :to="{name:'스포츠-인플레이(벳컨)'}" aria-label="스포츠실시간">인플레이</router-link></li>
                              <li><router-link :to="{name:'스포츠-한국형(벳컨)'}" aria-label="스포츠">한국형</router-link></li>
                              <li><router-link :to="{name:'스포츠-유럽형(벳컨)'}" aria-label="유럽형">유럽형</router-link></li>
                              <li><a class="menu" aria-label="라이브스포츠"  @click="openKplay(108)">
                                라이브스포츠
                              </a>
                              </li>
                          </ul>
                      </div>
                  </li>
                  <li class="">
                    <router-link :to="{name: '카지노-라이브'}" class="menu"  aria-label="카지노">카지노
                      <span class="icon-icconMENU"></span>
                    </router-link>
                  </li>
                  <!--<li class="">-->
                  <!--  <router-link :to="{name:'카지노-슬롯'}" class="menu" aria-label="슬롯" >슬롯-->
                  <!--    <span class="icon-icconMENU"></span>-->
                  <!--  </router-link>-->
                  <!--</li>-->
                  <li class="">
                    <a class="menu" aria-label="슬롯"  @click="openKplay(0)">
                      슬롯 
                      <span class="icon-icconMENU"></span>
                    </a>
                  </li>
                  <li>
                    <router-link :to="{name:'로투스-바카라1'}" class="menu" >로투스
                      <span class="icon-icconMENU"></span>
                    </router-link>
                      <div class="menu-drop">
                          <ul class="drop-ul">
                              <li><router-link :to="{name:'로투스-바카라1'}" aria-label="스포츠실시간">바카라1</router-link></li>
                              <li><router-link :to="{name:'로투스-홀짝'}" aria-label="스포츠">홀짝</router-link></li>
                          </ul>
                      </div>
                  </li>
                  <li>
                    <router-link :to="{name:'토큰-룰렛'}" class="menu" >토큰게임
                      <span class="icon-icconMENU"></span>
                    </router-link>
                      <div class="menu-drop">
                          <ul class="drop-ul">
                              <li><router-link :to="{name:'토큰-룰렛'}">룰렛</router-link></li>
                              <li><router-link :to="{name:'토큰-키노'}">키노</router-link></li>
                              <li><router-link :to="{name:'토큰-그래프'}">그래프</router-link></li>
                              <li><router-link :to="{name:'토큰-하이로우'}">하이로우</router-link></li>
                              <li><router-link :to="{name:'토큰-하이로우5초'}">하이로우5초</router-link></li>
                              <li><router-link :to="{name:'토큰-바카라'}">바카라</router-link></li>

                          </ul>
                      </div>
                  </li>
                  <li class="">
                    <a class="menu" aria-label="홀덤"  @click="openKplay(10002)">
                      홀덤 
                      <span class="icon-icconMENU"></span>
                    </a>
                  </li>
                  <li>
                    <router-link :to="{name:'토큰-룰렛'}" class="menu" >토큰게임
                      <span class="icon-icconMENU"></span>
                    </router-link>
                      <div class="menu-drop">
                          <ul class="drop-ul">
                              <li><router-link :to="{name:'토큰-룰렛'}" aria-label="룰렛">룰렛</router-link></li>
                              <li><router-link :to="{name:'토큰-하이로우'}" aria-label="하이로우">하이로우</router-link></li>
                              <li><router-link :to="{name:'토큰-하이로우5초'}" aria-label="하이로우5초">하이로우(5초)</router-link></li>
                              <li><router-link :to="{name:'토큰-바카라'}" aria-label="바카라">바카라</router-link></li>
                          </ul>
                      </div>
                  </li>
                  <li class="">
                    <router-link class="menu" :to="{name:'이벤트-목록'}">이벤트
                      <span class="icon-icconMENU"></span>
                    </router-link>
                      <div class="menu-drop">
                          <ul class="drop-ul">
                              <li>
                                <router-link :to="{name:'이벤트-목록'}" aria-label="정규 이벤트">정규
                                  이벤트
                                </router-link>
                              </li>
                              <!-- <li>
                                <a href="javascript:void(0);" aria-label="출석부"
                                     style="display: none;">출석부
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);" aria-label="럭키 휠"
                                     style="display: none;">룰렛</a>
                              </li> -->
                          </ul>
                      </div>
                  </li>
<!--                  <li class=""><a class="menu" href="javascript: void(0)"-->
<!--                                  aria-label="38 브랜드">-->
<!--                      <div>38 브랜드</div>-->
<!--                      <span class="icon-icconMENU"></span><img-->
<!--                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAASCAMAAAAXKszuAAAAsVBMVEUAAAD0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb////3cmj2Zlz6raj1TUD/+/r8xsL7uLP1Vkr93Nr7w774i4P4hHv1UUX0Rzr+8vL+6uj8z8z5kIj3eG/2ZFn2XVH0Sj3/9/b+8O/+7ev91dL8y8f5lo/4fnX2YFX94t/7vbn6q6X5oJn3bmU/FGDAAAAAFnRSTlMAoMYR+/Dv5qeXdyfu2I+CamhYTTUVDC3n8gAAAPhJREFUKM+VztlygkAUhGECbtHsSzczwxJ2RXCJZn3/BwsTCtAr4b889dWpNhYzXGxizi2j7hY9Gj/U+Aq9uhuC8TgET6wOl8IH8CEQ27q1L2IgEYU+rqGbd1gwq/QbYVPnRvSAI3dA7CyhM08x90mN3/Vn5aQJvshfhFxBd3OG6dXYFUKUCJiXmx2/4TKuSYWvG7z85KGdIVDtODLcZkqvaPB9g90i5aaZoaCcbeAoj5KHDj+1GLZDjT38F5ABcpJ+h61Ri7FiMyMDIjJCknKPBrejfRmi0hKF1P0ASkoFhDI/xa9T9MvQvYwHYGNh9sa659l0dBH/AfWjQ/2xxoy4AAAAAElFTkSuQmCC"-->
<!--                          class="newHint" style="display: none;"></a>-->
<!--                  </li>-->
<!--                  <li class=""><a class="menu" href="javascript: void(0)"-->
<!--                                  aria-label="KS 바로가기">-->
<!--                      <div><h4>KS 바로가기</h4></div>-->
<!--                      <span class="icon-icconMENU"></span><img-->
<!--                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAASCAMAAAAXKszuAAAAsVBMVEUAAAD0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb0Qzb////3cmj2Zlz6raj1TUD/+/r8xsL7uLP1Vkr93Nr7w774i4P4hHv1UUX0Rzr+8vL+6uj8z8z5kIj3eG/2ZFn2XVH0Sj3/9/b+8O/+7ev91dL8y8f5lo/4fnX2YFX94t/7vbn6q6X5oJn3bmU/FGDAAAAAFnRSTlMAoMYR+/Dv5qeXdyfu2I+CamhYTTUVDC3n8gAAAPhJREFUKM+VztlygkAUhGECbtHsSzczwxJ2RXCJZn3/BwsTCtAr4b889dWpNhYzXGxizi2j7hY9Gj/U+Aq9uhuC8TgET6wOl8IH8CEQ27q1L2IgEYU+rqGbd1gwq/QbYVPnRvSAI3dA7CyhM08x90mN3/Vn5aQJvshfhFxBd3OG6dXYFUKUCJiXmx2/4TKuSYWvG7z85KGdIVDtODLcZkqvaPB9g90i5aaZoaCcbeAoj5KHDj+1GLZDjT38F5ABcpJ+h61Ri7FiMyMDIjJCknKPBrejfRmi0hKF1P0ASkoFhDI/xa9T9MvQvYwHYGNh9sa659l0dBH/AfWjQ/2xxoy4AAAAAElFTkSuQmCC"-->
<!--                          class="newHint"></a>-->
<!--                  </li>&lt;!&ndash;&ndash;&gt;-->
              </ul>
          </div>
      </div>
  </div>
</template>

<script>
//import CurrentTime from '@/components/CurrentTime';
import {mapState} from "vuex";
import MSG from "@/contants/msg";
// import Loading from '@/components/Loading'
// import CurrentLanguage from '@/components/CurrentLanguage';

export default {
  name: "Header",
  components: {
    //CurrentTime,
    // Loading,
    // CurrentLanguage
  },
  props:['currentMenu'],
  data(){
    return {
      navHeader: false,
      toggle: false,
      toggle2: false,
      signInForm: {
        userId: null,
        userPw: null,
        sitename: 'lime',
        version: null,
      },
      subMenu: '',
      proceeding: false,
      kplayFlag: true,
    }
  },
  computed: {
    menuVisible(){
      return this.$store.getters['MENU_VISIBLE'];
    },
    inplayVisible(){
      return this.menuVisible?.find(e => e.name === '인플레이').visible
    },
    europeanVisible(){
      return this.menuVisible?.find(e => e.name === '스포츠-유럽형').visible
    },
    koreanVisible(){
      return this.menuVisible?.find(e => e.name === '스포츠-한국형').visible
    },
    korean2Visible(){
      return this.menuVisible?.find(e => e.name === '스포츠-한국형2').visible
    },
    liveVisible(){
      return this.menuVisible?.find(e => e.name === '라이브').visible
    },
    ...mapState({
      user: state => state.user.user,
      version: state => state.version.version,
    }),
  },
  methods:{
    logOut: function(){
      localStorage.removeItem('authToken');
      this.$store.dispatch("LOGOUT", null)
      this.$router.push({ path: '/main'})
    },
    handleScroll () {
      if (window.scrollY > 30) {
        this.navHeader = true;
      } else {
        this.navHeader = false;
      }
      // Any code to be executed when the window is scrolled
    },
    doLogin: function() {
      this.proceeding = true
      if(!this.signInForm.userId) return alert(MSG.error.emptyId);
      if(!this.signInForm.userPw) return alert(MSG.error.emptyPassword);
      this.signInForm.version = this.version
      this.$store.dispatch('LOGIN', this.signInForm)
          .then((response) => {
            if (response.status === 200) {
              // 로그인 성공시 처리
              alert(MSG.success.welcome);
              this.signInForm.userId = null
              this.signInForm.userPw = null
              this.proceeding = false
            }
          })
          .catch((error) => {
            // 로그인 실패시 처리
            console.log(error);
            alert('관리자에게 문의하세요');
            this.proceeding = false
            // alert(error.response.data.error);
          })
    },
    toggleModalTop: function() {
      this.toggle2 = !this.toggle2
      this.toggle = false;
    },
    toggleModalUser: function() {
      this.toggle = !this.toggle
      this.toggle2 = false;
    },
    subMouseOut(){
      // console.log('mouse out');
      this.subMenu = '';
    },
    subMouseIn(menu){
      // console.log(menu);
      this.subMenu = menu;
    },
    openCasino: function(game){
      if (!this.user) return alert(MSG.isNotLogin);
      const config = this.user.members_detail_setting;
      this.$store.dispatch('CHECK_CASINO_WALLET');
      if (game === 'evolution' && !this.menuVisible.find(e => e.name === '에볼루션').visible){
        return this.$store.dispatch('SHOW_ERR_MSG3', '에볼루션 점검중입니다.');
      }
      if (game === 'asia' && !this.menuVisible.find(e => e.name === '아시아').visible){
        return this.$store.dispatch('SHOW_ERR_MSG3', '아시아는 점검중입니다.');
      }
      if (game === 'evolution' && !config.베팅권한.에볼루션.active){
        this.$store.dispatch('SET_ERR_MSG', '에볼루션은 이용하실 수 없습니다.');
        this.$store.dispatch('SHOW_MODAL', 'error2');
        return;
      }
      if (game === 'asia' && !config.베팅권한.아시아게이밍.active){
        this.$store.dispatch('SET_ERR_MSG', '아시아게이밍은 이용하실 수 없습니다.');
        this.$store.dispatch('SHOW_MODAL', 'error2');
        return;
      }
      const data = {
        payload: {
          type: game === 'evolution' ? 45 : 44,
        }
      }
      this.$store.dispatch('GET_LOBBY', data)
          .then(data => {
            const result = data.data;
            if (result.payload) {
              window.open(result.payload.data.lobbyUrl, 'Casino', '_blank')
            }
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
    openKplay: function(product_id){
      if (!this.user) return alert(MSG.isNotLogin);
      if (!this.kplayFlag) return alert('처리 중입니다. 잠시만 기다려주세요')
      this.kplayFlag = false
      const data = {
        domain_url: window.location.origin + '/#/',
        product_id,
        "type": 0,
        "is_mobile": false,
        product_skin:2
      }
      this.$store.dispatch('GET_KPLAY', data)
          .then(data => {
            const result = data.data;
            if (result.url) {
              console.log()
              window.open(result.url, 'KPlay', '_blank')
              this.kplayFlag = true
            }
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

<style scoped>
.notLogin {
}
.nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 161px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 5px 20px #0d0f0f;z-index:10001;
}
/* util */
/* util */
.nav .util_wrap {width:100%; height:34px; float:left; background:#141415}
.nav .util_left {position:absolute; left:0; top:0}
.nav .util_right {position:absolute; right:20px; top:0;  line-height:34px;}
.util_left li{float:left;}
.util_time {width:90px; height:34px; line-height:34px; text-align:center;  border-right:solid 1px #2d3232; display:inline-block}
.util_btn {padding:0 20px 0 20px; margin:7px 0 0 0; line-height:25px; color:#ffffff; display:inline-block;  margin:4px 0 0 2px;}
.util_btn:hover {background:#3a4141}
.util_right li{float:right;margin:0 0 0 10px}
.util_money {color:#ffa800}
.util_point {color:#ffa800}

#nav-menu {
  border-bottom: 1px solid #A97BF5;
  border-top: 1px solid #353535;
}

.nav-shrink #nav-menu {
  border-top: 1px solid #353535;
}

img.imgLogo { height: 43px; width: 155px; margin-left:30px; margin-top: 15px;}

.top_wrap {width:100%; float:left; height:75px; background: #2d3232;}
.top_right {float:right; margin:22px 20px 0 0}
.top_right li {float:left;margin:0 0 0 5px}
.top_btn1 {position:relative; width:90px; height:34px; line-height:32px;  display:inline-block; background:#A97BF5; color:#ffffff; font-size:14px; text-align:center; font-weight:700; border-radius: 3px 3px 3px 3px;}
.top_btn1:hover {background:#981f25}
.top_btn2 {
  width:90px; height:34px; line-height:32px;  display:inline-block; background:#666969; color:#ffffff; font-size:14px; text-align:center; font-weight:700;
  cursor:pointer;
  border-radius: 3px 3px 3px 3px;
}
.top_btn2:hover {background:#717474}
.top_btn3 {width:90px; height:32px; line-height:32px;  display:inline-block; background:#ff8a00; color:#ffffff; font-size:14px; text-align:center; font-weight:700}
.top_btn3:hover {background:#ff8f0c}

.input_style0011 {border:solid 1px #505455; background:none; height:34px; line-height:34px; padding:0 0 0 10px; color:#c1c3c4; box-sizing: border-box; }

/* gnb */
.nav_wrap {width:100%; float:left; height:52px; }
.stroke {}
.stroke ul {text-align: center;}
.stroke ul li {display: inline-block;}
.stroke ul li a { display: block;padding: 15px 10px 18px 10px;text-decoration: none; color: #c1c3c4;font-weight: 700;text-transform: uppercase;margin: 0 20px;font-size:16px;}
.stroke ul li a,.stroke ul li a:after,.stroke ul li a:before {transition: all .5s;}
.stroke ul li a:hover {color: #ffffff;}
/* stroke */
.stroke ul li a{position: relative;}
.stroke ul li a:after {position: absolute;bottom: 0;left: 0; right: 0;margin: auto;width: 0%;content: '.';color: transparent; background: #A97BF5; height:2px;}
.stroke ul li a:hover:after {width: 100%;}

/* 스크롤시 header 변화 */
.nav-shrink {position:fixed; top: 0;left: 0;width: 100%; background:#2d3232; height:52px; z-index:10001; box-shadow: 0 5px 20px #0d0f0f;-webkit-transition: all 0.3s ease-in-out;transition: all 0.3s ease-in-out; border-bottom: 1px solid #ffaf14;}
.nav-shrink .util_wrap {display:none}
.nav-shrink .util_left {display:none}
.nav-shrink .util_right {float:right; margin:10px 430px 0 0}
.nav-shrink .top_wrap {height:1px;}
.nav-shrink .logo {position:absolute; left:0; top:0}
.nav-shrink .logo img {width:99px !important; height:23px !important;}

.nav-shrink .top_right {float:right; margin:10px 20px 0 0; }
.nav-shrink .stroke { position:absolute; top:0;}
.nav-shrink .stroke #nav-menu{ padding-left: 200px;}
.nav-shrink .stroke ul { text-align: left;}
.nav-shrink .stroke ul li a {display: block; padding: 17px 5px 18px 5px;text-decoration: none;color: #aaa;font-weight: 700;text-transform: uppercase;margin: 0 10px;font-size:14px;}
.nav-shrink .stroke ul li a:hover {color: #ffffff; }
.nav-shrink .popover-modal {margin:-2px 0 0 0}
.nav-shrink .sub_menu_wrap { width: 1800px}
.nav-shrink .top_right {position:absolute; right:0; top:0px; z-index: 300000}

ul#nav-menu li a:focus{
  outline:none!important;
}
.user-lvl{
  font-weight:700;
  margin-right:5px;
  font-size: 15px;
}

.nick-cont{
  /*width: 152px;*/
  /*height: 32px;*/
  /*background-position: 118px 0px;*/
  /*border: solid 1px #505455;*/
  margin-top: 7px;
  margin-right: 5px;
}
.nick{
  margin-top: 10px;
  margin-left: 10px;
  color: #c1c3c4;
  z-index: 2;
}
#lvl {
  cursor:pointer;
  position: relative;
  top: 8px;
  left: 4px;
  height: 22px;
  margin-right: 5px;
}

/* point_layer */
.popover-wrapper{position:relative}
.example-popover-2{-webkit-box-shadow:0 6px 12px rgba(0,0,0,.175);-moz-box-shadow:0 6px 12px rgba(0,0,0,.175);box-shadow:0 6px 12px rgba(0,0,0,.175);-webkit-transition:all 240ms cubic-bezier(.34,1.61,.7,1);-o-transition:all 240ms cubic-bezier(.34,1.61,.7,1);transition:all 240ms cubic-bezier(.34,1.61,.7,1);-webkit-transform:scale(0);transform:scale(0);transform-origin:29px -10px;opacity:0;position:absolute;z-index:1000;margin-top:5px;border-radius:10px;background-color:#141616}
.popover-wrapper.open .example-popover-2{-webkit-transform:scale(1);transform:scale(1);opacity:1}
.popover-wrapper.open .btn-dropdown{outline:0}
.example-popover-2:before{position:absolute;top:-16px;left:16px;border:8px solid transparent;border-bottom-color:#aab2bd}
.example-popover-2:after{position:absolute;top:-14px;left:17px;border:7px solid transparent;border-bottom-color:#fff}
.example-popover-2 .popover-body{margin:0;padding:4px 0;max-height:300px;overflow:scroll}
.example-popover-2 .popover-body.popover-body-padded{padding:8px}
.example-popover-2 .popover-header .popover-close-btn{float:right}
.popover-wrapper.right .example-popover-2{transform-origin:275px -10px;right:0}
.popover-wrapper.right .example-popover-2:before{left:auto;right:16px}
.popover-wrapper.right .example-popover-2:after{left:auto;right:17px}
.example-popover-2 .popover-body { overflow:hidden; padding:30px 20px 20px 20px; width:244px; }
.popover-body-in {width:100%; float:left;  height:20px; line-height:20px;}
.popover_t {float:left}
.popover_money {color:#ffa800; float:right}
.popover_point { color:#ffa800; float:right}
.popover-body-btn {width:100%; float:left; margin:15px 0 0 0}
.popover-body-btn_in {width:100%; float:left; margin:0 0 2px 0}
.popover_btn {width:120px; height:33px; border-radius:5px; background:#363636; text-align:center; line-height:33px; display:inline-block}
.popover_btn:hover {background:#444444}

.nav-counter {
 position:absolute;
 right: 1px;
 width:12px;
 height:12px;
 border-radius: 6px;
 margin-top: -4px;
 margin-right: -4px;
 font-weight: normal;
 font-size: small;
 color: white;
 text-align: center;
 text-shadow: 0 1px rgba(0, 0, 0, 0.2);
 background: #e23442;
 /* border: 1px solid #911f28; */
 background-image: -webkit-linear-gradient(top, #e8616c, #dd202f);
 background-image: -moz-linear-gradient(top, #e8616c, #dd202f);
 background-image: -o-linear-gradient(top, #e8616c, #dd202f);
 background-image: linear-gradient(to bottom, #e8616c, #dd202f);
 -webkit-box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.1), 0 1px rgba(0, 0, 0, 0.12);
 box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.1), 0 1px rgba(0, 0, 0, 0.12);
}

#notification {
  background: #e23442;
  /* border: 1px solid #911f28; */
  position: absolute;
}


.sub_menu_wrap{
  background: #000 url(../assets/img/menu/mline_oran.png) 50% bottom no-repeat;
}
.sub_menu_wrap ul{
  display: flex;
  align-items: center;
  width: 1280px;
  margin: 0 auto;
  flex-wrap: wrap;
  background: url(../assets/img/menu/line_oran.png) no-repeat center bottom;
}
.sub_menu_wrap ul:last-child{
  background: none;
}

.sub_menu_wrap ul li a {
  position: relative;
  display: block;
  width: 170px;
  line-height: 28px;
  font-size: 14px;
  text-align: center;
  color: #ccc;
  margin: 0 auto;
  padding: 0;
}
.sub_menu_wrap ul li{
  margin-right: 52px;
  margin-bottom: 25px;
  margin-top: 25px;
}
.sub_menu_wrap ul li:last-child{
  margin-right: 0;
}
.sub_menu_wrap ul li a img {
  display: block;
  width: 170px;
  height: 170px;
  border-radius: 10px;
  border: 1px solid #111;
}
.sub_menu_wrap ul li a div {
  display: table-cell;
  width: 170px;
  height: 170px;
  border-radius: 10px;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
  border: 1px solid #111;
}
.sub_menu_wrap ul li a:hover img{
  border-color: #A97BF5;
}
.sub_menu_wrap ul li a:hover div{
  border-color: #A97BF5;
}
.sub_menu_wrap ul li a:hover {
  color: #A97BF5;
}
.sub_menu_wrap >div {
  display:none
}
</style>
