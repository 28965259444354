import axios from 'axios';
import store from "@/store";
const API_URL = process.env.VUE_APP_API_URL;
const getDefaultState = () => {
  return {
    originList: [],
    waitList: [],
    inplayLink: [],
    inplayStared: [],
    cart: [],
  }
}

const state = getDefaultState()

const getters = {
  //카트 안에 담긴 배당내역의 총합
  INPLAY_TOTAL_ODDS: function(state) {
    return state.cart.reduce((prev, curr) => prev * Number(curr.odds), 1).toFixed(2);
  },
}

const actions = {
  GET_INPLAY_LIST({commit}, params) {
    return axios.post(`${API_URL}/v2/inplay/list?sitename=lime`, params)
      .then((response) => {
        if (response.data){
          commit('SET_INPLAY_LIST', response.data?.payload.list)
        }
        return response
      })
  },
  GET_INPLAY_WAIT_LIST({commit}, params) {
    return axios.post(`${API_URL}/v2/inplay/wait/list`, params)
      .then((response) => {
        if (response.data){
          commit('SET_INPLAY_WAIT_LIST', response.data?.payload.list)
        }
        return response
      })
  },
  GET_INPLAY_DETAIL(_, params) {
    return axios.post(`${API_URL}/v2/inplay/detail?sitename=lime`, params)
      .then((response) => {
        return response
      })
  },
  UPDATE_INPLAY_LIST({commit}, data) {
    commit('SET_IN_PLAY_BET_UPDATE', data)
  },
  ADD_CART_INPLAY_ITEM({state, commit, getters}, obj){
    console.log(obj)
    const checkFolderCount = store.getters['LEVEL_SETTING'].prematchEuropeanSetting.maxFolders;
    const cartInItemCnt = state.cart.filter(e => e.betType !== '보너스').length;

    if (checkFolderCount <= cartInItemCnt) return alert(`최대가능 폴더수는 ${checkFolderCount}개 입니다.`)
    console.log(3333);
    // 카트에 동일경기 아이템이 추가되었을때
    const equalCart = state.cart.find(item => item.foldersSeq === obj.foldersSeq);
    if (equalCart) return commit('POP_ITEM_INPLAY_CART_BY_FOLDER', obj);
    const checkMaxOdds = store.getters['LEVEL_SETTING'].prematchEuropeanSetting.maxOdds;
    if (Number(getters.INPLAY_TOTAL_ODDS) * Number(obj.odds) >  Number(checkMaxOdds)) return alert('최대배당을 초과하셨습니다.')

    const cartItem = state.cart.find(item => item.betsSeq === obj.betsSeq);
    if (!cartItem) {
      // 추가할 item 이 카트의 게임과 일치하지 않을 경우, 카트에 새로 추가
      // 동일경기는 기본적인 동일경기 설정
      const dupItem = state.cart.filter(item => item.gameId === obj.gameId);

      if ( dupItem.length >= 1 ) return alert('동일경기에서 조합은 불가능합니다.')

      commit('PUSH_ITEM_INPLAY_CART', obj);
    } else {
      commit('POP_ITEM_INPLAY_CART', obj); // 있을시 삭제
    }
  },
  ALL_INPLAY_CLEAR_ITEM({ commit }){
    return commit('RESET_INPLAY_CART')
  },
  TOGGLE_STARED({commit}, data){
    commit('SET_IN_PLAY_LINKED_LIST', data)
  },
  DELETE_CART_INPLAY_ITEM({ commit }, obj){
    commit('POP_ITEM_INPLAY_CART', obj); // 있을시 삭제
  },

  DELETE_CART_INPLAY_BY_GAME_SEQ({ commit }, obj){
    commit('POP_ITEM_INPLAY_CART_BY_GAME_SEQ', obj); // 있을시 삭제
  },
  DELETE_CART_INPLAY_BY_GAME_ID({ commit }, id){
    commit('POP_ITEM_INPLAY_CART_BY_GAME_ID', id); // 있을시 삭제
  },
}

const mutations = {
  SET_INPLAY_LIST(state, payload) {
    state.originList = payload
  },
  SET_INPLAY_WAIT_LIST(state, payload) {
    state.waitList = payload
  },
  SET_DATE(state, payload) {
    state.dateCnt = payload
  },
  SET_IN_PLAY_BET_UPDATE(state, data) {
    for (const i in data){
      const gameData = data[i];
      const game = state.originList.find(e => String(e.id) === String(gameData.MatchId))
      // console.log(game);
      // const config = store.state.setting.settings.sportConfig.common;
      if (game) {
        // 폴더들
        const folder = game.folders.find(e => String(e.id) === String(gameData.Id))
        if (folder) {
          folder.isVisible = gameData.IsVisible ? 'y' : 'n';
          folder.isSuspended = gameData.IsSuspended ? 'y' : 'n';
          for (const b in gameData.Selections){
            const socketBet = gameData.Selections[b];
            const bet = folder.bets.find(e => String(e.id) === String(socketBet.Id))
            if (bet) bet.price = Number(socketBet.Price).toFixed(2);
          }
        }
      }
    }
  },

  RESET_INPLAY_CART(state){
    state.cart = [];
  },
  SET_IN_PLAY_LINKED_LIST(state, data) {
    const index = state.inplayLink.findIndex(e => e.id === data.id)
    if (index >= 0){
      return state.inplayLink.splice(index, 1);
    }
    return state.inplayLink.push(data);
  },
  POP_ITEM_INPLAY_CART(state, obj){
    const index = state.cart.findIndex(i => i.betsSeq === obj.betsSeq);
    if (index === -1) return state.cart = [...state.cart, obj];
    return state.cart.splice(index, 1);
  },
  PUSH_ITEM_INPLAY_CART(state, obj){
    state.cart.push(obj)
  },
  POP_ITEM_INPLAY_CART_BY_FOLDER(state, obj){
    const index = state.cart.findIndex(i => i.foldersSeq === obj.foldersSeq);
    if (index === -1) return state.cart = [...state.cart, obj];
    return state.cart.splice(index, 1);
  },
  POP_ITEM_INPLAY_CART_BY_GAME_SEQ(state, obj){
    const index = state.cart.findIndex(i => i.gameSeq === obj.gameSeq);
    if (index === -1) return state.cart = [...state.cart, obj];
    return state.cart.splice(index, 1);
  },
  POP_ITEM_INPLAY_CART_BY_GAME_ID(state, id){
    const index = state.cart.findIndex(i => String(i.gameId) === String(id) && i.isCheck === 'n');
    if (index === -1) return false;
    return state.cart.splice(index, 1);
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
