<template>
  <div class="vfm vfm--inset vfm--fixed" style="z-index: 1000;" v-if="isOpen">
    <div class="vfm__overlay vfm--overlay vfm--absolute vfm--inset"></div>
    <div data-v-2836fdb5=""
         class="vfm__container vfm--absolute vfm--inset vfm--outline-none model-main popup-login model-open"
         aria-expanded="true" role="dialog" aria-modal="true" tabindex="-1" style="">
      <div class="vfm__content modal-content">
<!--        <header id="header">-->
<!--          <div class="mask" style="display: none;"></div>-->
<!--          <div class="wrapper wallet wrapper-notlogin"><a-->
<!--              class="icon-left-menu"><span-->
<!--              class="icon-nav2"></span></a><a class="guestmail">비회원 문의</a><a-->
<!--              class="logo"><img src="/assets/logo-5KdY4KVu.svg" alt="logo"></a>-->
<!--            <div class="btn-box">-->
<!--              <button class="signup-btn">회원가입</button>-->
<!--              <button class="login-btn">로그인</button>-->
<!--            </div>&lt;!&ndash;&ndash;&gt;&lt;!&ndash;&ndash;&gt;</div>-->
<!--        </header>-->
        <div class="model-inner modal-fade-in">
          <div class="model-wrap">
            <div class="pop-up-content">
              <div class="login-content">
                <div class="close-btn hide"><span class="icon-close"></span>
                </div>
                <div class="input-area">
                  <form>
                    <div class="input-list">
                      <div class="list">
                        <div class="txt"><span class="icon-id"></span></div>
                        <input name="account" type="text" placeholder="아이디" required="" v-model="signInForm.userId">
                      </div>
                    </div>
                    <div class="input-list">
                      <div class="list">
                        <div class="txt"><span class="icon-pw"></span></div>
                        <input type="password" placeholder="영문자로 시작하는 2~16자의 영문자와 숫자 조합"
                               required="" name="password" v-model="signInForm.userPw">
                      </div><!---->
                    </div>
                    <div class="input-list-forget"><input
                        id="styled-checkbox-forget"
                        type="checkbox" class="styled-checkbox"
                        name="isRemember" value="true"><label
                        for="styled-checkbox-forget"><span
                    >아이디저장</span></label></div>
                    <div class="bgbtn-box">
                      <button class="login-button btnColor06" type="button" @click="doLogin">
                        <div>로그인</div>
                      </button>
                      <button class="signupnow-button btnColor03" type="button" @click="$store.dispatch('INVISIBLE_MODAL', 'signup')">
                        <div>회원가입</div>
                      </button>
                      <button class="guestmail-button" type="button" @click="$store.dispatch('INVISIBLE_MODAL', 'login')">
                        <div>닫기</div>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div><!--v-if-->
      </div>
    </div>
  </div>
</template>

<script>
//import Loading from '@/components/Loading'
import MSG from '@/contants/msg'
import {mapState} from "vuex";

export default {
  name: "LoginModal",
  data() {
    return {
      signInForm: {
        userId: null,
        userPw: null,
        sitename: 'day',
        version: null,
      },
      proceeding: false,
    }
  },
  computed: {
    ...mapState({
      isOpen: state => state.modals.login,
    }),
  },
  methods: {
    doLogin: function () {
      if (!this.signInForm.userId) return alert(MSG.error.emptyId);
      if (!this.signInForm.userPw) return alert(MSG.error.emptyPassword);
      this.signInForm.version = this.version
      this.$store.dispatch('LOGIN', this.signInForm)
          .then((response) => {
            if (response.status === 200) {
              // 로그인 성공시 처리
              alert(MSG.success.welcome);
              this.signInForm.userId = null
              this.signInForm.userPw = null
              this.$store.dispatch('INVISIBLE_MODAL', 'login')
            }
          })
          .catch(() => {
            // 로그인 실패시 처리
            alert('로그인에 실패했습니다. 관리자에 문의 하세요.');
            // alert(error.response.data.error);
          })
    },
  }
}
</script>


<style scoped>
.vfm--inset {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.vfm--fixed {
  position: fixed;
}
.vfm--overlay{
  background-color: rgba(0, 0, 0, 0.5);
}
.vfm--absolute {
  position: absolute;
}
.popup-login .model-inner {
  width: 75vw;
  max-width: 75vw;
  margin: 0 auto;
}
.model-inner {
  margin-top: 52px;
  background-color: #ffffff;
}
.modal-content #header, .modal-content .model-inner {
  pointer-events: initial;
}
.model-inner {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -moz-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translateY(-25%);
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: -webkit-transform .2s ease-out;
  transition: -webkit-transform .2s ease-out;
  -o-transition: -o-transform .2s ease-out;
  -moz-transition: transform .2s ease-out, -moz-transform .2s ease-out;
  transition: transform .2s ease-out;
  transition: transform .2s ease-out, -webkit-transform .2s ease-out, -moz-transform .2s ease-out, -o-transform .2s ease-out;
}
.model-wrap {
  display: block;
  width: 100%;
  position: relative;
  background-clip: padding-box;
  outline: 0;
  text-align: left;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: auto;
}

.login-content {
  background: var(--bgColor_Grad06-00);
  background: -webkit-linear-gradient(top, var(--bgColor_Grad06-00) 1%, var(--bgColor_Grad06-01) 100%);
  background: -moz-linear-gradient(top, var(--bgColor_Grad06-00) 1%, var(--bgColor_Grad06-01) 100%);
  background: -o-linear-gradient(top, var(--bgColor_Grad06-00) 1%, var(--bgColor_Grad06-01) 100%);
  background: linear-gradient(to bottom, var(--bgColor_Grad06-00) 1%, var(--bgColor_Grad06-01) 100%);
  padding: 8.6vw 8.8vw;
}

.input-area .list {
  margin: 1vw 0;
  font-size: .93rem;
  padding: 0vw 2vw;
  background: var(--bgColor01);
  border: 1px solid var(--borderColor03);
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.input-area .list input {
  width: 100%;
  height: 10vw;
  padding: 2vw 0vw 2vw 2vw;
  font-size: .86rem;
}

.input-list-forget {
  margin: 2vw 0 3vw;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
  width: 4.6vw;
  height: 4.6vw;
}

.input-list-forget .styled-checkbox+label {
  font-size: .81rem;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.bgbtn-box {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.login-content button.login-button {
  margin-bottom: 1vw;
  background: var(--bgColor02);
  color: var(--txtColor01);
}
.pop-up-content button.signupnow-button {
  width: 49%;
  margin-right: 2%;
}
.pop-up-content button.guestmail-button {
  width: 49%;
  background: var(--bgColor40);
  color: var(--txtColor42);
}
</style>
