<template>
  <section id="content" class="">
    <div class="wrapPage v_deep_service_center">
      <ul class="tab-list">
        <li><router-link :to="{ name: '베팅내역' }">베팅내역</router-link></li>
        <li class="active"><a>고객센터</a></li>
        <li><router-link :to="{ name: '쪽지-목록' }">쪽지</router-link></li>
      </ul>
      <div class="wrapper">
        <div class="title-p">
          <div>고객센터</div>
          <span>Customer</span></div>
        <div class="content-table">
          <div>
            <div class="letterZone">
              <div class="content-time customer-time">
                <span>충전할려고하는데 자꾸 잠겨있다고 해서</span>
                <div class="other-info">
                  <span class="nick-name">jay048896</span>
                  <span class="time">2024/08/16 06:24:12</span>
                </div>
              </div>
              <div class="content-info" v-html="renderHtml(article.board_content)">
            </div>
            </div>
            <div class="letterZone" v-for="(row, index) in article.comments" :key="'comments-'+index">
              <div class="content-time reply-time"><!---->
                <button class="btnColor10">답변완료</button>
                <div class="other-info"><!---->
                  <span class="nick-name">관리자</span>
                  <span class="time">{{ row.createdAt | formatDate('YYYY-MM-DD HH:mm:ss') }}</span>
                </div>
              </div>
              <div class="content-info reply-info" v-html="renderHtml(row.content)">
              </div>
            </div>
          </div>
          <div class="btn-center">
            <button class="return-button" @click="$router.push({name:'고객센터-목록'})">목록으로</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Detail",
  data: function(){
    return {
      article: null,
    }
  },
  mounted: function() {
    this.reqData()
  },
  methods: {
    reqData: function () {
      return this.$store.dispatch('GET_BOARD_DETAIL', { seq: this.$route.params.seq })
          .then(data => {
            const result = data.data;
            this.article = result.payload.article;
            console.log(this.article);
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    renderHtml: function(str){
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    },
  }
}
</script>

<style scoped>

</style>
