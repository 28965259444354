<template>
  <div class="vfm vfm--inset vfm--fixed" style="z-index: 1000;" v-if="isOpen">
    <div class="vfm__overlay vfm--overlay vfm--absolute vfm--inset"></div>
    <div
        class="vfm__container vfm--absolute vfm--inset vfm--outline-none model-main popup-signup model-open"
        aria-expanded="true" role="dialog" aria-modal="true" tabindex="-1" enter-class="vue-modal-enter-from"
        leave-class="vue-modal-leave-from" style="">
      <div class="vfm__content model-inner">
        <div class="model-wrap">
          <div class="pop-up-content">
            <div class="close-btn" @click="$store.dispatch('INVISIBLE_MODAL', 'signup')">
              <span class="icon-close"></span>
            </div>
            <div class="head-top">
              <div class="title-name"><img
                  src="@/assets/img/main/main_toplogo.png"
                  alt="logo">
                <b class="titleP">회원가입</b>
              </div>
            </div>
            <div class="signup-content">
              <div class="input-area">
                <form>
                  <div class="input-list">
                    <div class="list list-id">
                      <div class="txt"><span class="icon-id"><em
                      >*</em></span></div>
                      <input type="text" name="account" placeholder="ID (영문, 숫자 포함 4자 이상)" class="" v-model="signUpForm.userId">
                      <div class="checkUse ko-KR-member"></div>
                    </div>
<!--                    <button class="check-button" type="button">중복</button>&lt;!&ndash;&ndash;&gt;-->
                    <div class="alarm" style="display: none;">계정등록이 가능합니다.</div>
                  </div>
                  <div class="input-list">
                    <div class="list full-list">
                      <div class="txt"><span class="icon-pw"><em
                      >*</em></span></div>
                      <input type="password" placeholder="비밀번호 (영문, 숫자 포함 6자이상)" class=""
                             name="password" v-model="signUpForm.userPw">
                      <div class="checkUse"></div>
                    </div><!---->
                    <div class="rule-hint" style="display: none;">
                      <div class="txt-hint">비밀번호는 아래의 조건이 포함되어야 합니다 :</div>
                      <ul>
                        <li class="">
                          <div class="checkUse"></div>
                          <p>최소 6자, 최대 16자</p></li>
                        <li class="">
                          <div class="checkUse"></div>
                          <p>영숫자 조합</p></li>
                        <li class="">
                          <div class="checkUse"></div>
                          <p>(선택)특수기호!@#$%^&amp;*()+_</p></li>
                      </ul>
                    </div>
                  </div>
                  <div class="input-list">
                    <div class="list full-list">
                      <div class="txt"><span class="icon-pw"><em
                      >*</em></span></div>
                      <input type="password" placeholder="비밀번호 확인 (영문, 숫자 포함 6자이상)" class=""
                             name="confirmPassword" v-model="signUpForm.userPw2">
                      <div class="checkUse"></div>
                    </div><!----></div>
                  <div class="input-list">
                    <div class="list full-list">
                      <div class="txt"><span class="icon-pw"><em
                      >*</em></span></div>
                      <input type="password" pattern="[0-9]*" inputmode="numeric"
                             placeholder="출금 비밀번호 ( 4-6자리, 숫자만 가능 )" class="" name="moneyPassword" v-model="signUpForm.cashoutPw">
                      <div class="checkUse"></div>
                    </div><!----></div>
                  <div class="input-list">
                    <div class="list full-list">
                      <div class="txt"><span class="icon-phone"><em
                      >*</em></span></div>
                      <input type="text" pattern="[0-9]*" inputmode="numeric"
                             placeholder="휴대폰번호 (숫자만 입력가능)" class="" name="mobile" v-model="signUpForm.contact"><!---->
                      <div class="checkUse"></div>
                    </div><!----></div>
<!--                  <div class="input-list">-->
<!--                    <div class="list full-list">-->
<!--                      <div class="txt"><span class="icon-carrier"><em-->
<!--                      >*</em></span></div>-->
<!--                      <input type="text" class="carrier-btn" placeholder="통신사 선택" readonly=""-->
<!--                             name="mobileCarrier">-->
<!--                      <div class="checkUse"></div>-->
<!--                    </div>&lt;!&ndash;&ndash;&gt;-->
<!--                  </div>-->
                  <div class="input-list input-birth">
                    <div class="list full-list">
                      <div class="txt"><span class="icon-calendar"><em
                      >*</em></span></div>
                      <div class="license-number">
                        <div class="number-box"><input type="text"
                                                       pattern="[0-9]*" inputmode="numeric"
                                                       class="input-one" placeholder="예시)19760325"
                                                       name="birthday" v-model="signUpForm.birthday"></div>
                      </div>
                      <div class="checkUse"></div>
                    </div><!----></div>
<!--                  <div class="input-list input-birth">-->
<!--                    <div class="list full-list">-->
<!--                      <div class="txt"><span-->

<!--                          class="icon-gender"><em-->
<!--                      >*</em></span></div>-->
<!--                      <div class="license-number">-->
<!--                        <div class="number-box">-->
<!--                          <ul class="sex">-->
<!--                            <li><input-->
<!--                                id="male" type="radio" name="sex"-->
<!--                                value="true"><label-->

<!--                                for="male">남</label>-->
<!--                            </li>-->
<!--                            <li><input-->
<!--                                id="female" type="radio" name="sex"-->
<!--                                value="false"><label-->

<!--                                for="female">여</label>-->
<!--                            </li>-->
<!--                          </ul>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="checkUse"></div>-->
<!--                    </div>&lt;!&ndash;&ndash;&gt;</div>-->
                  <div class="line"></div>
                  <div class="input-list">
                    <div class="list icon-black full-list">
                      <div class="txt"><span class="icon-id"><em
                      >*</em></span></div>
                      <input type="text" placeholder="예금주 변경이 불가능합니다" class="" name="name" v-model="signUpForm.bankDepositor">
                      <div class="checkUse"></div>
                    </div><!----></div>
                  <div class="input-list">
                    <div class="list icon-black full-list">
                      <div class="txt"><span class="icon-bank-s"><em
                      >*</em></span></div>
                      <select v-model="signUpForm.bankName" style="text-align: center; height: 35px;">
                        <option :value="null">--은행선택--</option>
                        <option v-for="(row, index) in bank_name" :key="'bankName' +index" :value="row.name">{{ row.name }}</option>
                      </select>
                      <div class="checkUse"></div>
                    </div><!----></div>
                  <div class="input-list">
                    <div class="list icon-black full-list">
                      <div class="txt"><span class="icon-bank"><em
                      >*</em></span></div>
                      <input type="text" pattern="[0-9]*" inputmode="numeric"
                             placeholder="계좌(계좌을 입력해주세요)" class="" name="bankAccount" v-model="signUpForm.bankAccount">
                      <div class="checkUse"></div>
                    </div><!----></div>
                  <div class="input-list">
                    <div class="list icon-black full-list">
                      <div class="txt"><span class="icon-recommend"><em
                          style="display: none;">*</em></span></div>
                      <input type="text" placeholder="가입코드를 입력하세요" class="" name="parentAccount" v-model="signUpForm.code">
                      <div class="checkUse"></div>
                    </div><!---->
                    <div class="alarm">*가입코드 미입력 시 본사코드로 가입됩니다.</div>
                  </div>
                  <div class="bgbtn-box">
                    <button class="signup-button" type="submit" @click="doSignup">
                      <div>즉시가입</div>
                    </button>
                    <button class="cancel-button" type="button" @click="$store.dispatch('INVISIBLE_MODAL', 'signup')">
                      <div>취소</div>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div><!--v-if--></div>
    </div>
  </div>
</template>

<script>
import MSG from "@/contants/msg";
import {mapState} from "vuex";

export default {
  name: "Signup",
  data: () => ({
    userId: null,
    userIdMsg: null,
    userPw: null,
    userPw2: null,
    nickname: null,
    nicknameMsg: null,
    code: null,
    codeMsg: null,
    phone: null,
    bankName: null,
    owner: null,
    account: null,
    cashoutPw: null,
    cashoutPw2: null,
    signUpForm: {
      userId: null,
      nickname: null,
      userPw: null,
      contact: null,
      cashoutPw: null,
      bankDepositor: null,
      bankName: null,
      bankAccount: null,
      code: '',
      referrer: '',
    },
    infoAgree: false,
    idPass: false,
    codePass: false,
    nicknamePass: false,
  }),
  watch: {
    'signUpForm.userId': function (val) {
      this.userId = val.replace(/[^a-zA-Z0-9]/gi, '')
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

      if (reg.exec(val) !== null) {
        return this.signUpForm.userId = this.signUpForm.userId.slice(0, -1);
      }
    },
  },
  computed: {
    bank_name: function () {
      return this.$store.getters['BANK_SETTING']
    },
    ...mapState({
      isOpen: state => state.modals.signup,
    }),
  },
  methods: {
    doSignup: function () {
      // if (!this.infoAgree) errMsg = '개인정보 수집 및 이용동의를 체크 해주세요');
      let errMsg = '';
      if (this.signUpForm.userPw !== this.signUpForm.userPw2) errMsg = MSG.error.difPw
      if (!this.signUpForm.userId) errMsg = MSG.error.emptyId;
      if (!this.signUpForm.userPw) errMsg = MSG.error.emptyPassword;
      if (!this.signUpForm.userPw2) errMsg = MSG.error.emptyPassword;
      if (this.signUpForm.userPw.length <= 7) errMsg = MSG.error.shortPassword;
      if (!this.signUpForm.nickname) errMsg = MSG.error.emptyNick;
      if (!this.signUpForm.code) errMsg = MSG.error.emptyCode;
      if (!this.signUpForm.contact) errMsg = MSG.error.emptyContact;
      if (!this.signUpForm.bankName) errMsg = MSG.error.emptyBank;
      if (!this.signUpForm.bankDepositor) errMsg = MSG.error.emptyDepositor;
      if (!this.signUpForm.bankAccount) errMsg = MSG.error.emptyBankNum;
      if (!this.signUpForm.cashoutPw) errMsg = MSG.error.emptyWithdrawalPw;
      if (errMsg !== '') return alert(errMsg);
      this.isProcess = true;
      return this.$store.dispatch('SIGNUP', this.signUpForm)
          .then(() => {
            alert(MSG.success.signup);
            this.$router.push('/main')
          })
          .catch((error) => {
            alert(error.response.data.error);
            // alert(error.response.data.error);
          })
    },
    checkRefererCode: function () {
      const data = {};
      data.payload = {
        code: this.code,
        siteName: 'lime'
      };
      this.$store.dispatch('auth/checkCode', data).then(data => {
        if (data.success) {
          this.codePass = true;
          this.codeMsg = null;
        }
        if (!data.success) {
          this.codePass = false;
          this.codeMsg = data.msg;
        }
      })
    },
  },
}
</script>


<style scoped>
.vfm--inset {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.vfm--fixed {
  position: fixed;
}
.vfm--overlay{
  background-color: rgba(0, 0, 0, 0.5);
}
.vfm--absolute {
  position: absolute;
}
.model-inner {
  background-color: #ffffff;
}
.modal-content #header, .modal-content .model-inner {
  pointer-events: initial;
}
.model-inner {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -moz-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translateY(-25%);
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: -webkit-transform .2s ease-out;
  transition: -webkit-transform .2s ease-out;
  -o-transition: -o-transform .2s ease-out;
  -moz-transition: transform .2s ease-out, -moz-transform .2s ease-out;
  transition: transform .2s ease-out;
  transition: transform .2s ease-out, -webkit-transform .2s ease-out, -moz-transform .2s ease-out, -o-transform .2s ease-out;
}
.model-wrap {
  display: block;
  width: 100%;
  position: relative;
  background-clip: padding-box;
  outline: 0;
  text-align: left;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: auto;
}

.head-top {
  position: relative;
  width: 100%;
  height: auto;
  background-color: var(--bgColor01);
  border-bottom: 1px solid var(--txtColor02);
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.popup-signup .title-name {
  padding: 2.6vw 0;
}

.signup-content {
  background-color: var(--bgColor01);
}

.popup-signup .input-area {
  margin: 0 auto;
  padding: 3.8vw 0vw;
}
.input-area .list {
  margin: 1vw 0;
  font-size: .93rem;
  padding: 0vw 2vw;
  background: var(--bgColor01);
  border: 1px solid var(--borderColor03);
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.input-area .list .txt {
  width: 8vw;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.popup-signup .input-area .input-list {
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.popup-signup .input-area .input-list {
  padding: 0vw 3.8vw;
  margin-bottom: 2vw;
}
.popup-signup .input-area .input-list .list-id {
  width: 100%;
}

.signup-content .list {
  padding: 0;
}
.popup-signup .input-area .list {
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0;
}
.signup-content button.check-button {
  width: 20vw;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--txtColor02);
  background: var(--btnColor03);
  font-size: .81rem;
  height: 10vw;
  padding: 1vw;
  font-weight: 600;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  font-size: .93rem;
}

.signup-content .list input {
  padding: 2vw;
  border: 0px solid var(--borderColor05);
}
.popup-signup .input-area .list span {
  padding: 0vw 2vw;
  border-right: 1px solid var(--borderColor03);
  color: var(--txtColor32);
}
.input-area .list .input input, .input-area .full-list .input input, .input-area .full-list input {
  width: 75vw;
}
.input-area .list .input input, .input-area .full-list .input input, .input-area .full-list select {
  width: 75vw;
}
.signup-content .list select {
  padding: 2vw;
  border: 0px solid var(--borderColor05);
}
.signup-content .list .checkUse {
  width: 3.6vw;
  height: 3.6vw;
  margin-right: 2.6vw;
  background-repeat: no-repeat;
  -o-background-size: contain;
  background-size: contain;
  background-position: center left;
}
.popup-signup .alarm {
  width: 90vw;
  padding-left: 10vw;
  margin-top: 1vw;
}
.signup-content .bgbtn-box {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 6vw;
  padding: 0vw 3.8vw;
}
.signup-content button.signup-button {
  background: var(--bgColor02);
  color: var(--txtColor01);
}
.signup-content .bgbtn-box button {
  width: 44.6vw;
}
</style>
