<template>
  <!-- Pagination -->
  <div class="pagination">
    <ul>
      <li class="first"><a @click="movePage(1)">처음</a></li>
      <li class="prev"><a @click="movePage(1)">이전</a></li>
      <li class="pages" v-for="row in pagination.end" :class="{'on' : payload.page === row}" @click="movePage(row)" :key="'pagination'+ row">
        <a @click="movePage(row)">{{ row }}</a>
      </li>
      <li class="next"><a @click="movePage(pagination.end)">다음</a></li>
      <li class="last"><a @click="movePage(pagination.end)">끝</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['pagination', 'payload', 'reqData'],
  name: "Pagination",
  methods: {
    movePage: function (data) {
      this.payload.page = data;
      this.reqData(this.payload);
    },
  },
}
</script>

<style>
.paging02 a {
  width: 20px;
}

.paging02 a .on {
  border: 1px solid #A97BF5;
  color: #A97BF5;
}
</style>
