<template>
  <div class="allContent noticeContent" v-if="!isMobile">
    <div class="rightContent homePage">
      <div class="contents">
        <div class="main_content_continer">
          <div class="main-content">
            <div class="aniFrame">
              <div class="titlePic">
                <div class="titlePwrapper">
                  <div class="leftZone"><span class="Logo"></span><span>공지</span></div>
                  <div class="line"></div>
                </div>
              </div>
              <div class="main_content">
                <div class="main_content">
                  <div class="main_content_wrap main_content_wrap_notice">
                    <div class="noticeFrame noticeInnerFrame"><!---->
                      <div class="contentZone">
                        <div class="time">
                          <div class="pageBack">
                            <a @click="$router.push({name:'이벤트-목록'})"><span class="icon-icconArrow02"></span> 뒤로</a>
                            <div class="crossLine"></div>
                          </div>
                          <div class="title">{{ article.board_title }}</div>
                          <span class="timeR"><!----></span>
                        </div>
                        <div> <img :src="article.board_img" style="width: 100%;" alt=""></div>
                        <div class="txt" v-html="renderHtml(article.board_content)">
                        </div>
                        <div class="btnFrame">
                          <button class="btnP print goldLLineBtn blueLLineBtn01 btnBlack01"
                                  @click="$router.push({name:'이벤트-목록'})">목록으로이동
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section id="content" v-else class="">
    <div class="wrapPage">
      <ul class="tab-list">
        <li >
          <router-link :to="{ name: '이벤트-목록'}">공지</router-link>
        </li>
        <li class="active">
          <router-link :to="{ name: '이벤트-목록'}">이벤트</router-link>
        </li>
      </ul>
      <div class="wrapper">
        <div class="title-p">
          <div>이벤트</div>
          <span>Event</span></div><!---->
        <div>
          <div class="content-time"><span>{{ article.board_title }}</span><!----></div>
          <div class="content-info">
            <img :src="article.board_img" alt="">
          </div>
          <div class="content-info" v-html="renderHtml(article.board_content)"></div>
          <div class="btn-end">
            <button class="return-button btnColor05" @click="$router.push({ name: '이벤트-목록' })">목록으로</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Detail",
  props: ['isMobile'],
  data: function(){
    return {
      article: null,
    }
  },
  mounted: function() {
    this.reqData()
  },
  methods: {
    reqData: function () {
      return this.$store.dispatch('GET_BOARD_DETAIL', { seq: this.$route.params.seq })
          .then(data => {
            const result = data.data;
            this.article = result.payload.article;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    renderHtml: function(str){
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    }
  }
}
</script>

<style scoped>

</style>
