<template>
  <div class="allContent noticeContent" v-if="!isMobile">
    <div class="rightContent homePage">
      <div class="contents">
        <div class="main_content_continer">
          <div class="main-content">
            <div class="aniFrame">
              <div class="titlePic">
                <div class="titlePwrapper">
                  <div class="leftZone"><span class="Logo"></span><span
                  >쪽지</span></div>
                  <div class="line"></div>
                </div>
              </div>
              <div class="main_content">
                <div class="main_content">
                  <div class="main_content_wrap main_content_wrap_notice deep_wrap_notice">
                    <div class="messageFrame noticeFrame noticeInnerFrame"><!----><!---->
                      <div class="contentZone">
                        <div class="time">
                          <div class="pageBack">
                            <router-link :to="{ name:'쪽지-목록'}">
                              <span class="icon-icconArrow02"></span>
                              뒤로
                            </router-link>
                            <div class="crossLine"></div>
                          </div>
                          <div class="title">{{ article.title }}</div>
                          <span class="timeR">{{ article.createdAt | formatDate('YYYY-MM-DD HH:mm:ss') }}</span></div>
                        <div class="txt content-body">
                        </div>
                        <div class="btnFrame">
                          <button @click="$router.push({name: '쪽지-목록'})" class="btnP print blueLLineBtn01">목록으로</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section v-else id="content" class="">
    <div class="wrapPage v_deep_mail">
      <ul class="tab-list">
        <li><a>베팅내역</a></li>
        <li><a>고객센터</a></li>
        <li class="active"><a>쪽지</a></li>
      </ul>
      <div class="wrapper">
        <div class="title-p">
          <div>쪽지</div>
          <span>Inbox</span></div>
        <div class="content-table">
          <div>
            <div class="content-time"><span>{{ article.title }}</span><span
                class="time">{{ article.createdAt | formatDate('YYYY-MM-DD HH:mm:ss') }}</span></div>
            <div class="content-info content-body" v-html="renderHtml(article.content)"></div>
          </div>
          <div class="btn-spbtw btn-center">
            <button class="backpage-button btn-submit-common">이전쪽지</button>
            <button class="return-button btn-submit-common">목록으로</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotesDetail",
  props: ['isMobile'],
  components: {},
  data: function () {
    return {
      article: null,
    }
  },
  mounted: function () {
    this.reqData()
  },
  methods: {
    reqData: function () {
      const seq = this.$route.params.seq;
      const path = `notes/detail/${seq}`;
      return this.$store.dispatch('auth/post', {path, data: {payload: this.payload}})
          .then(data => {
            const result = data.data;
            this.article = result.payload.article;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    deleteNotes: function (item) {
      if (confirm('삭제 하시겠습니까?')) {
        return this.$store.dispatch('auth/post', {path: 'notes/delete', data: {payload: {item}}})
            .then(data => {
              const result = data.data;
              alert(result.msg);
              this.reqData();
            }).catch(err => {
              alert(err.response.data.error);
            })
      }
    },
    movePage: function (page) {
      this.$router.push({path: `/${page}`})
    },
    renderHtml: function (str) {
      if (!str) return '';
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    },
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
